import { useEffect, useState, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage"

import { Context } from 'store/index'

import Viewer from './map-viewer'
import Uploader from './map-uploader'

export default function Index({ project }) {
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, 'gs://flightpack-assets')

  const [state, dispatch] = useContext(Context)
  const [uploads, setUploads] = useState(false)

  const [loaded, setLoaded] = useState(false)
  const [numFiles, setNumFiles] = useState(false)
  const [filePaths, setFilePaths] = useState({})

  const [loadedDsm, setLoadedDsm] = useState(false)
  const [numFilesDsm, setNumFilesDsm] = useState(false)
  const [filePathsDsm, setFilePathsDsm] = useState({})

  useEffect(() => {
    function doDig(folder) {
      listAll(folder).then((res) => {
        if(res.prefixes && res.prefixes.length > 0) {
          res.prefixes.forEach(ref => {
            doDig(ref)
          })
        }
        if(res.items && res.items.length > 0) {
          setNumFiles(prev => prev + res.items.length)
          res.items.forEach(ref => {
            getDownloadURL(ref).then(downloadURL => {
              // const name = ref._location.path.replace(/^.*[\\\/]/, '')
              const name = ref._location.path.replace(path+'/', '')
              setTimeout(() => {
                setFilePaths(prev => ({
                  ...prev,
                  [name]: {
                    url: downloadURL
                  }
                }))
              }, 100)
            })
          })
        }
      }).catch(e => {
        console.log(e)
      })
    }

    // const path = 'site-analysis/test/orthophoto_tiles'
    const path = 'site-analysis/'+project.id+'/orthophoto_tiles'
    // const path = 'site-analysis/'+project.id+'/dsm_tiles'
    const listRef = ref(storage, path)
    doDig(listRef)

    function doDigDsm(folder) {
      listAll(folder).then((res) => {
        if(res.prefixes && res.prefixes.length > 0) {
          res.prefixes.forEach(ref => {
            doDigDsm(ref)
          })
        }
        if(res.items && res.items.length > 0) {
          setNumFilesDsm(prev => prev + res.items.length)
          res.items.forEach(ref => {
            getDownloadURL(ref).then(downloadURL => {
              // const name = ref._location.path.replace(/^.*[\\\/]/, '')
              const name = ref._location.path.replace(pathDsm+'/', '')
              setTimeout(() => {
                setFilePathsDsm(prev => ({
                  ...prev,
                  [name]: {
                    url: downloadURL
                  }
                }))
              }, 100)
            })
          })
        }
      }).catch(e => {
        console.log(e)
      })
    }

    const pathDsm = 'site-analysis/'+project.id+'/dsm_tiles'
    const listRefDsm = ref(storage, pathDsm)
    doDigDsm(listRefDsm)
  }, [])

  useEffect(() => {
    if(numFiles && numFiles > 0 && filePaths && (numFiles === Object.keys(filePaths).length)) {
      setLoaded(true)
    }
  }, [numFiles, filePaths])

  useEffect(() => {
    if(numFilesDsm && numFilesDsm > 0 && filePathsDsm && (numFilesDsm === Object.keys(filePathsDsm).length)) {
      setLoadedDsm(true)
    }
  }, [numFilesDsm, filePathsDsm])

  return (
    <div className='fade-in relative h-screen flex items-center justify-center'>
      {!loaded && !loadedDsm &&
        <div className='text-center'>
          <p>Loading...</p>
          <p>{(Object.keys(filePaths).length/ numFiles * 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%</p>
        </div>
      }
      {loaded && loadedDsm &&
        <Viewer project={project} filePaths={filePaths} filePathsDsm={filePathsDsm} />
      }
      {!loaded || !loadedDsm || !uploads || uploads.status.value !== 'Ready' &&
        <Uploader uploads={uploads} setUploads={setUploads} />
      }
    </div>
  )
}
