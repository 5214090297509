import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

export default function Index() {
  return (
    <div className='fade-down fixed top-0 left-0 m-3 z-10 flex gap-2 items-center'>
      <Link
        to='/'
        className='z-10 group text-primary flex items-center gap-2 bg-black-825 hover:bg-black-800 flex h-8 px-3 pr-4 transition duration-200 rounded-full hover:bg-black-760'>
        <ArrowLeftIcon strokeWidth={2.5} className='transition duration-200 group-hover:scale-125 h-4 w-4 m-auto' />
        <span className='text-xs font-bold'>Projects</span>
      </Link>
    </div>
  )
}
