import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/24/solid'

import { InView } from 'react-intersection-observer'

export default function Index({ projects }) {
  return (
    <ul className='fade-in flex flex-col gap-3'>
      {projects.map(doc => {
        let progress = 0
        switch(doc.status.value) {
          case 'Created':
            break
          case 'Error':
            break
          case 'Cancelled':
            break
          case 'Queued':
            progress = 15
            break
          case 'Processing':
            progress = 33
            break
          case 'Completed':
            progress = 66
            break
          case 'Built':
            progress = 100
            break
          default:
            break
        }
        return (
          <InView threshold={.25} key={doc.id}>
            {({ inView, ref, entry }) => {
              return (
                <li ref={ref} className={'relative bg-black-875 rounded-xl overflow-hidden block text-sm flex items-stretch gap-2 transition duration-500 transform ' + (inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-6')}>
                  <div className='z-0 m-1 shrink-0 w-24 block aspect-1-1 rounded-full overflow-hidden'>
                    <img className='z-0 h-full w-full transform scale-125 object-cover rounded-full overflow-hidden' src={'https://maps.googleapis.com/maps/api/staticmap?center='+doc.lat+','+doc.lng+'&zoom=18&size=160x160&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY} />
                  </div>
                  <div className='flex flex-col justify-between pr-2 py-2 flex-1'>
                    <div>
                      <p className='block truncate'>{doc.address}</p>
                      <p className='pt-1 text-black-400 text-xs truncate'>
                        {doc.createdAt.toDate().toLocaleDateString('en-US', {
                          month: 'short',
                          day: "numeric",
                          year: 'numeric'
                        })}
                      </p>
                    </div>
                    <div className='flex justify-between items-end w-full'>
                      <div className='flex-1'>
                        <p className='pb-1 text-xxs text-black-500'>{doc.status.value}</p>
                        <div className='bg-black-750 rounded-full overflow-hidden h-1 w-1/3'>
                          <div className='h-full bg-primary rounded-full transition-all duration-200' style={{ width: progress + '%'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link className='self-end m-2 group flex h-9 w-9 rounded-full text-center bg-black-800 hover:bg-black-775 transition duration-200' to={'projects/'+doc.id}>
                    <EyeIcon className='h-4 w-4 m-auto text-primary transition duration-200 group-hover:scale-125' />
                  </Link>
                </li>
              )}}
          </InView>
        )
      })}
      {projects.length < 6 && [...Array(6 - projects.length)].map((a,i) =>
        <InView threshold={.25} key={'filler-' + i}>
          {({ inView, ref, entry }) => {
            return (
              <li ref={ref} className={'relative bg-black-875 rounded-xl overflow-hidden block text-sm flex items-stretch gap-2 transition duration-500 transform ' + (inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-6')}>
                <div className='z-0 m-1 shrink-0 w-24 block bg-black-825 aspect-1-1 rounded-full overflow-hidden'>
                </div>
                <div className='flex flex-col justify-between pr-2 py-2 flex-1'>
                  <div>
                    <p className='block truncate'>&nbsp;</p>
                    <p className='pt-1 text-black-400 text-xs truncate'>
                      &nbsp;
                    </p>
                  </div>
                  <div className='flex justify-between items-end w-full'>
                    <div className='flex-1'>
                      <p className='pb-1 text-xxs text-black-500'>&nbsp;</p>
                      <div className='bg-black-750 rounded-full overflow-hidden h-1 w-1/3'>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='m-2 self-end group flex h-9 w-9 rounded-full text-center bg-black-800 hover:bg-black-775 transition duration-200'>

                </div>
              </li>
            )}}
        </InView>
      )}
    </ul>
  )
}
