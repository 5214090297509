import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, doc, onSnapshot, Timestamp } from 'firebase/firestore'

import { Context } from 'store/index'

import { Cog8ToothIcon, PencilIcon, TrashIcon, MapPinIcon, CubeIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import Projects from 'components/menu-main/projects'

import ProjectSettings from './components/project-settings'
import Uploader from './components/uploader'
import Processing from './components/processing'
import Queued from './components/queued'
import Error from './components/error'
import Canceled from './components/canceled'
import Building from './components/building'
import Model from './components/model'
import Map from './components/map'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [state, dispatch] = useContext(Context)
  const { id } = useParams()

  const mounted = useRef(null)
  const projectsUnsub = useRef(null)

  const [view, setView] = useState('model')
  const [project, setProject] = useState(false)

  const [status, setStatus] = useState('created')

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = null
      if(projectsUnsub.current) projectsUnsub.current()
      dispatch({
        type: 'current-project-set',
        payload: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getData() {
      if(!project || parseInt(project.id) !== parseInt(id)) {
        setProject(false)
        try {
          if(projectsUnsub.current) projectsUnsub.current()
          projectsUnsub.current = onSnapshot(doc(db, 'site-analysis', id.toString()), { includeMetadataChanges: true }, (doc) => {
            if(mounted.current && doc.exists()) {
              const c = {
                id: doc.id
              }
              Object.entries(doc.data()).forEach(([key, value]) => {
                if(value instanceof Timestamp) {
                  c[key] = value.seconds * 1000 + value.nanoseconds / 1000000
                } else {
                  c[key] = value
                }
              })
              setProject({
                id: parseInt(doc.id),
                ...c
              })
            } else {
              setProject(null)
            }
          })
        } catch(e) {
          console.log(e)
        }
      }
    }
    if(id) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    dispatch({
      type: 'current-project-set',
      payload: project
    })
  }, [project])

  return(
    <>
      <div className='fixed top-20vh left-3 z-10'>
        <div className='fade-right bg-black-850 rounded-lg w-44 px-2 pt-4 pb-2'>
          <p className='text-xs'>{project.name}</p>
          <p className='text-xs text-black-400'>{project.title}</p>
          <ProjectSettings />
        </div>
      </div>
      <Projects />
      {project === false &&
        <div className='fade-in relative h-screen flex items-center justify-center'>
          Loading
        </div>
      }
      {project &&
        <>
          {(project.status.value === 'Created' || project.status.value === 'Pushing') &&
            <Uploader project={project} />
          }
          {project.status.value === 'Queued' &&
            <Queued project={project} />
          }
          {(project.status.value === 'Processing' || project.status.value === 'Queued') &&
            <Processing project={project} />
          }
          {(project.status.value === 'Error' || project.status.value === 'Failed') &&
            <Error project={project} />
          }
          {project.status.value === 'Canceled' &&
            <Canceled project={project} />
          }
          {project.status.value === 'Completed' &&
            <Building project={project} />
          }
          {project.status.value === 'Built' &&
            <div className='fade-in relative h-screen'>
              {/*<div className='fixed top-20vh left-10vw z-10'>
                <div data-aos='fade-in' className='flex flex-col gap-1'>
                  <button onClick={() => setView('model')} className={'border border-black-925 group shadow-lg h-9 w-9 transition duration-200 rounded-full flex ' + (view === 'model' ? 'bg-primary hover:brightness-110' : 'bg-white hover:bg-black-100')}>
                    <CubeIcon className={'transition duration-200 group-hover:scale-125 h-4.5 w-4.5 m-auto ' + (view === 'model' ? 'text-black' : 'text-black')} />
                  </button>
                  <button onClick={() => setView('map')} className={'border border-black-925 group shadow-lg h-9 w-9 transition duration-200 rounded-full flex ' + (view === 'map' ? 'bg-primary hover:brightness-110' : 'bg-white hover:bg-black-100')}>
                    <MapPinIcon className={'transition duration-200 group-hover:scale-125 h-4.5 w-4.5 m-auto ' + (view === 'map' ? 'text-black' : 'text-black')} />
                  </button>
                </div>
              </div>*/}
              {view === 'model' && <Model project={project} />}
              {view === 'map' && <Map project={project} />}
            </div>
          }
        </>
      }
    </>
  )
}
