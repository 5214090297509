import { Link } from 'react-router-dom'

import { EyeIcon } from '@heroicons/react/24/solid'
import { InView } from 'react-intersection-observer'

export default function Index({ projects }) {
  return (
    <ul className='fade-in grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-3 gap-y-3'>
      {projects.map(doc => {
        let progress = 0
        switch(doc.status.value) {
          case 'Created':
            break
          case 'Error':
            break
          case 'Cancelled':
            break
          case 'Queued':
            progress = 15
            break
          case 'Processing':
            progress = 33
            break
          case 'Completed':
            progress = 66
            break
          case 'Built':
            progress = 100
            break
          default:
            break
        }
        return (
          <InView threshold={.25} key={doc.id}>
            {({ inView, ref, entry }) => {
              return (
                <li ref={ref} className={'rounded-xl overflow-hidden block text-sm bg-black-875 transition duration-500 transform ' + (inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-6')} to={'projects/'+doc.id}>
                  <div className='flex justify-between'>
                    <div className='m-1 block aspect-1-1 w-24 rounded-xl overflow-hidden'>
                      <img className='transform scale-125 h-full w-full object-cover rounded-xl overflow-hidden' src={'https://maps.googleapis.com/maps/api/staticmap?center='+doc.lat+','+doc.lng+'&zoom=18&size=160x160&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY} />
                    </div>
                  </div>
                  <div className='block p-2 pt-6'>
                    <div className='block line-clamp-2 text-black-400'>{doc.name ? doc.name : <span>&nbsp;</span>}</div>
                    <div className='block line-clamp-2 h-12'>{doc.title}</div>
                    <div className='block text-black-400 text-xs truncate pb-6'>
                      {doc.createdAt.toDate().toLocaleDateString('en-US', {
                        month: 'short',
                        day: "numeric",
                        year: 'numeric'
                      })}
                    </div>
                    <div className='flex justify-between items-end'>
                      <div className='flex-1'>
                        <p className='pb-1 text-xxs text-black-500'>{doc.status.value}</p>
                        <div className='bg-black-750 rounded-full overflow-hidden h-1 w-1/2'>
                          <div className='h-full bg-primary rounded-full transition-all duration-200' style={{ width: progress + '%'}} />
                        </div>
                      </div>
                      <Link className='self-end group flex h-9 w-9 rounded-full text-center bg-black-800 hover:bg-black-775 transition duration-200' to={'projects/'+doc.id}>
                        <EyeIcon className='h-4 w-4 m-auto text-primary transition duration-200 group-hover:scale-125' />
                      </Link>
                    </div>
                  </div>
                </li>
              )}}
          </InView>
        )
      })}
    </ul>
  )
}
