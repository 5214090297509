import { useRef, useEffect, useState, useContext, useCallback } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { Context } from 'store/index'

import { useDropzone } from 'react-dropzone'
import { CloudArrowUpIcon } from '@heroicons/react/24/solid'

export default function Index({ project }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const [state, dispatch] = useContext(Context)

  const [task, setTask] = useState(false)

  useEffect(() => {
    const upload = state.uploads.find(u => u.id === project.id)
    if(upload) {
      setTask(upload)
    } else {
      setTask(false)
    }
  }, [state.uploads])

  const onDrop = useCallback(async (acceptedFiles) => {
    dispatch({ type: 'start-uploads-set', payload: [{
      id: project.id,
      full_address: project.full_address,
      address: project.address,
      lat: project.lat,
      lng: project.lng,
      cid: project.cid,
      uid: state.user.uid,
      name: project.name,
      files: acceptedFiles,
      progress: 0,
      duration: '0s',
      status: {
        value: 'Pushing',
        description: 'Pushing to cloud...',
      },
      updatedAt: new Date(),
      createdAt: new Date(),
      startedAt: new Date()
    }]})
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className='fade-in relative h-screen flex items-center justify-center'>
      <div className='w-full max-w-md'>
        {!task &&
          <div {...getRootProps()} className='pt-6 pb-7 gap-6 w-full text-center rounded-2xl transition duration-200 bg-black-875 hover:ring ring-primary flex flex-col items-center justify-center text-sm'>
            <input {...getInputProps()}
              webkitdirectory='true'
              mozdirectory='true'
              directory='true'
              multiple={true}
            />
            <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-800'>
              <CloudArrowUpIcon className='h-6 w-6 text-primary' />
            </div>
            {isDragActive ?
              <p>Drop when you're ready...</p> :
              <>
                <p>Drop your site analysis <span className='text-primary'>drone photographs</span> here<br />to begin processing a 3d model.</p>
                <p className='text-xs text-black-400'>You can also click here to browse your<br />computers file finder.</p>
              </>
            }
          </div>
        }
        {task &&
          <div className='gap-6 w-full rounded-2xl flex flex-col items-center justify-center text-sm'>
            <div className='flex flex-col gap-6 text-center'>
              <div>
                <p>{task.status.description}</p>
                <p className='text-xs text-black-400'>Don't leave this page during the upload process.</p>
              </div>
              <div className='bg-black-850 px-3 py-6 pb-7 flex text-xs rounded-2xl'>
                <div className='w-32'>
                  <p className='text-black-400'>Pushing</p>
                  <p>{task.progress.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%</p>
                </div>
                <div className='w-32'>
                  <p className='text-black-400'>Duration</p>
                  <p>{task.duration}</p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
