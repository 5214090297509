import { Link } from 'react-router-dom'
import { useState, useContext } from 'react'

import { Context } from 'store/index'

import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default function Index() {
  const auth = getAuth()
  const [state, dispatch] = useContext(Context)

  const [error, setError] = useState(false)
  const [credentials, setCredentials] = useState({
    email: ''
  })

  async function submit(e) {
    if(!credentials.email) {
      setError('Please enter a valid email address.')
    } else {
      dispatch({ type: 'toast-set', payload: 'Sending email.' })
      sendPasswordResetEmail(auth, credentials.email)
      .then(() => {
        dispatch({ type: 'toast-set', payload: false })
        setTimeout(() => {
          dispatch({ type: 'toast-set', payload: 'Email sent!' })
        }, 400)
      })
      .catch((error) => {
        console.log(error.message)
        dispatch({ type: 'toast-set', payload: error.message })
      })
    }
  }

  return (
    <div data-aos='fade-in' className='h-screen w-screen flex'>
      <form className='fade-in m-auto w-screen max-w-xs space-y-8'
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}>
        <p className='text-center text-2xl'>
          Forgot Password
        </p>
        <div className='p-1 overflow-hidden relative'>
          <input
            id='email'
            className='placeholder-black-400 w-full bg-black-825 rounded-lg appearance-none pt-3.5 pb-4 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='email'
            value={credentials.email}
            placeholder='Email Address'
            onChange={(e) => setCredentials(prev => ({ ...prev, email: e.target.value }))} />
        </div>
        {error &&
          <div className='font-mono text-black-400 text-sm uppercase fade-in text-center border-2 border-primary rounded-lg py-4'>{error}</div>
        }
        <div>
          <button type='submit' className='block mx-auto bg-black-825 hover:bg-black-800 text-primary transition duration-200 pt-2 pb-2.5 px-8 font-bold rounded-full'>Send reset email</button>
        </div>
        <div className='text-center'>
          <Link to='/login' className='mx-auto mt-6'>Back to login</Link>
        </div>
      </form>
    </div>
  )
}
