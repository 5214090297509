import { useState } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, doc, setDoc, deleteField } from 'firebase/firestore'

import { CheckIcon } from '@heroicons/react/24/solid'

export default function Index({ modalRef, user, access }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [firstName, setFirstName] = useState(user.firstName ? user.firstName : '')
  const [lastName, setLastName] = useState(user.lastName ? user.lastName : '')
  const [roles, setRoles] = useState('')

  function checkForm() {
    return firstName && lastName && roles.length
  }

  function changeChecked(roleId) {
    if(!isChecked(roleId)) {
      console.log('not checked')
      setRoles(prev => ([
        ...prev, { id: roleId }
      ]))
    } else {
      console.log('checked')
      setRoles(prev => prev.filter(el => el.id.toString() !== roleId.toString()))
    }
  }

  function isChecked(roleId) {
    return roles && roles.filter(el => el.id.toString() === roleId.toString()).length > 0
  }

  return (
    <div className='max-w-full w-96 space-y-6 p-6'>
      <p className='text-center text-2xl'>Edit User</p>
      <div className='p-3 bg-black-800 rounded-2xl'>
        <div className='text-sm space-y-2'>
          <label className='text-xs block text-black-200'>Email</label>
          <input
            type='email'
            disabled={true}
            className='text-black-300 rounded-lg bg-black-725 w-full focus:outline-none p-3'
            value={user.email}
            placeholder='Email'
          />
          <label className='text-xs block text-black-200'>First Name</label>
          <input
            type='text'
            name='firstName'
            className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='First Name'
            autoComplete='new-password'
          />
          <label className='text-xs block text-black-200'>Last Name</label>
          <input
            type='text'
            name='lastName'
            className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='Last Name'
            autoComplete='new-password'
          />
          <label className='text-xs block text-black-200'>Access</label>
          {access &&
            <ul className='text-sm bg-black-725 rounded-lg divide-y divide-black-775'>
              {access.map(item => {
                const id = item.id
                const data = item.data()
                return (
                  <li key={id}>
                    <button
                      className='flex text-left items-center gap-3 w-full px-2 py-2'
                      onClick={(e) => {
                        changeChecked(id)
                      }}>
                      <span className='h-6 w-6 flex'>
                        <CheckIcon className={'m-auto h-5 w-5 ' + (isChecked(id) ? 'text-primary' : 'text-black-300')} />
                      </span>
                      <span className='flex-1'>{data.name}</span>
                    </button>
                  </li>
                )
              })}
            </ul>
          }
        </div>
      </div>
      <button
        disabled={checkForm() ? false : true}
        className={'mx-auto font-bold block py-1.5 text-sm px-8 rounded-full ' + (checkForm() ? 'bg-primary text-black' : 'bg-black-800 text-black-200')}
        onClick={async () => {
          await setDoc(doc(db, 'users', user.uid), {
            updatedAt: new Date(),
            firstName: firstName,
            lastname: lastName,
            roles: {
              ...user.roles,
              flightpack: roles.length ? roles : deleteField()
            }
          }, { merge: true })

          modalRef.current.close()
        }}>
        Save
      </button>
    </div>
  )
}
