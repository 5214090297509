import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { createPortal } from 'react-dom'

import { Link } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'store/index'

import { BellIcon, EyeIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)
  const [alerts, setAlerts] = useState(false)
  const [list, setList] = useState(false)
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)
  const alertsUnsub = useRef(false)
  const taskTimer = useRef(false)
  const unloadListener = useRef(false)

  const firstLoad = useRef(false)

  const timeAgo = new TimeAgo('en-US')

  useEffect(() => {
    mounted.current = true
    getData()

    return () => {
      mounted.current = false
      if(alertsUnsub.current) alertsUnsub.current()
      clearTimers()
      clearBeforeUnload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const uploads = state.startUploads
    dispatch({ type: 'start-uploads-set', payload: false})
    if(uploads && uploads.length > 0) {
      startUploads(uploads)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.startUploads])

  useEffect(() => {
    if(state.uploads && state.uploads.length > 0) {
      startBeforeUnload()
      startTimers()
    } else {
      clearBeforeUnload()
      clearTimers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uploads])

  useEffect(() => {
    if(state.uploads && state.uploads.length > 0) {
      startBeforeUnload()
      startTimers()
    } else {
      clearBeforeUnload()
      clearTimers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uploads])

  useEffect(() => {
    let update = []
    if(state.uploads && state.uploads.length > 0) {
      update = [
        ...state.uploads.map(u => ({
          ...u,
          type: 'upload'
        }))
      ]
    }
    if(alerts && alerts.length > 0) {
      update = [
        ...update,
        ...alerts.map(a => ({
          ...a,
          type: 'alert'
        }))
      ]
    }
    setList(update.length > 0 ? update : false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, state.uploads])

  function startTimers() {
    if(!taskTimer.current) {
      console.log('start timers')
      taskTimer.current = setInterval(() => {
        calcTime()
      }, 1000)
    }
  }

  function clearTimers() {
    if(taskTimer.current) {
      console.log('clear timers')
      clearInterval(taskTimer.current)
      taskTimer.current = null
    }
  }

  function startBeforeUnload() {
    if(!unloadListener.current) {
      console.log('listen')
      window.addEventListener('beforeunload', confirmRefresh)
      unloadListener.current = true
    }
  }

  function clearBeforeUnload() {
    if(unloadListener.current) {
      console.log('unlisten')
      window.removeEventListener('beforeunload', confirmRefresh)
      window.beforeunload = null
      unloadListener.current = null
    }
  }

  function doError(upload, data) {
    console.log('Error:', data.error)
    setDoc(doc(db, 'site-analysis', upload.id), {
      status: {
        value: 'Error',
        description: data.error,
      },
      updatedAt: new Date()
    }, { merge: true })
    setDoc(doc(db, 'site-analysis-alerts', upload.id), {
      status: {
        value: 'Error',
        description: data.error,
      },
      updatedAt: new Date()
    }, { merge: true })
    dispatch({ type: 'uploads-remove', payload: {
      id: upload.id
    }})
  }

  function confirmRefresh(e) {
    e.preventDefault()
    e.returnValue = ''
    return window.confirm('Leaving this page will cancel your current uploads!')
  }

  async function getData() {
    if(alertsUnsub.current) alertsUnsub.current()

    const q = query(collection(db, 'site-analysis-alerts'), where('uid', '==', state.user.uid), limit(25))

    alertsUnsub.current = onSnapshot(q, (querySnapshot) => {
      if(mounted.current) {
        if(!querySnapshot.empty) {
          setAlerts(querySnapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            }
          }))
          querySnapshot.docChanges().forEach(change => {
            if(firstLoad.current) {
              if(change.type === 'added') {
                console.log('Added: ', change.doc.data())
                // dispatch({
                //   type: 'toast-set',
                //   payload: change.doc.data().status.value
                // })
              }
              if(change.type === 'modified') {
                console.log('Modified: ', change.doc.data())
              }
              if(change.type === 'removed') {
                console.log('Removed: ', change.doc.data())
              }
            }
          })
        } else {
          setAlerts(null)
        }
        firstLoad.current = true
      }
    })
  }

  function calcTime() {
    console.log('calc time')
    state.uploads.forEach(upload => {
      dispatch({ type: 'uploads-update', payload: {
        id: upload.id,
        duration: timeAgo.format(upload.startedAt, 'mini')
      }})
    })
  }

  function startUploads(uploads) {
    console.log('start uploads')

    uploads.forEach(async upload => {
      console.log('init')
      dispatch({ type: 'uploads-add', payload: upload })

      const options = [
        { name: 'tiles', value: true },
        { name: 'cog', value: true },
        { name: 'orthophoto-png', value: true },
        { name: 'pc-quality', value: 'high' },
        { name: 'dsm', value: true },
        { name: 'skip-report', value: true },
        { name: 'auto-boundary', value: true },
      ]
      const body = new URLSearchParams({
        name: upload.address,
        options: JSON.stringify(options),
        webhook: 'https://us-central1-risingsun-solar.cloudfunctions.net/odmLightningWebhook'
      })
      const response = await fetch('http://spark1.webodm.net/task/new/init?' + new URLSearchParams({
          token: process.env.REACT_APP_ODM_LIGHTNING
        }), {
      	method: 'POST',
        body: body
      })
      const data = await response.json()

      if(data.error) {
        doError(upload, data)
      } else {
        setDoc(doc(db, 'site-analysis', upload.id), {
          uid: state.user.uid,
          taskUuid: data.uuid,
          updatedAt: new Date(),
          startedAt: upload.startedAt
        }, { merge: true })
        dispatch({ type: 'uploads-update', payload: {
          id: upload.id,
          taskUuid: data.uuid
        }})
        pushImages(data.uuid)
      }

      async function pushImages(uuid) {
        console.log('push')
        const formData = new FormData()

        let totalSize = 0
        for(const file of upload.files) {
          totalSize += file.size
          formData.append('images', file)
        }

        const request = new XMLHttpRequest()
        request.open('POST', 'http://spark1.webodm.net/task/new/upload/'+uuid+'?' + new URLSearchParams({
          token: process.env.REACT_APP_ODM_LIGHTNING
        }))

        request.onload = async (e) => {
          if(request.status === 200) {
            console.log('commit')
            const response = await fetch('http://spark1.webodm.net/task/new/commit/' + uuid + '?' + new URLSearchParams({
                token: process.env.REACT_APP_ODM_LIGHTNING
              }), {
            	method: 'POST'
            })
            const data = await response.json()
            if(data.error) doError(upload, data)
          } else {
            doError(upload, { error: request.status })
          }
        }

        request.upload.onprogress = (e) => {
          const progress = (e.loaded / e.total) * 100
          console.log('progress')

          dispatch({ type: 'uploads-update', payload: {
            id: upload.id,
            progress: progress
          }})

          if(progress === 100) {
            setDoc(doc(db, 'site-analysis', upload.id), {
              status: {
                value: 'Processing',
                description: 'Your model is being processed...',
              },
              updatedAt: new Date()
            }, { merge: true })
            setDoc(doc(db, 'site-analysis-alerts', upload.id), {
              uid: state.user.uid,
              taskUuid: uuid,
              id: upload.id,
              full_address: upload.full_address,
              address: upload.address,
              lat: upload.lat,
              lng: upload.lng,
              cid: upload.cid,
              name: upload.name,
              startedAt: upload.startedAt,
              status: {
                value: 'Processing',
                description: 'Your model is being processed...',
              },
              updatedAt: new Date()
            }, { merge: true })
            dispatch({ type: 'uploads-remove', payload: {
              id: upload.id,
            }})
          }
        }
        request.send(formData)
      }
    })
  }

   return (
    <div className='fade-up fixed bottom-0 right-10vw flex gap-2 z-20'>
      <div className='h-9 hover:h-12 transition-[height] duration-300'>
        <button
          onClick={() => setShow(!show)}
          className={'relative group bg-primary flex justify-center h-full items-center px-3 gap-2 transition duration-300 rounded-t-xl w-screen max-w-sm ' + (list && list.length > 0 ? 'translate-y-0 mt-1' : 'translate-y-full')}>
          {false && alerts && alerts.length > 0 &&
            <span className='fade-in -mt-px -ml-px flex items-center justify-center text-black text-xxs block h-3.5 w-3.5 bg-primary rounded-full absolute top-0 left-0'>
              <span className='leading-none block -mt-px'>{alerts.length}</span>
            </span>
          }
          <BellIcon className='h-4 w-4 text-black transition transform duration-300 group-hover:scale-125' />
        </button>
      </div>
      <Modal showModal={show} setShowModal={setShow} list={list} />
    </div>
  )
}

function Modal({ showModal, setShowModal, list }) {
  const [state, dispatch] = useContext(Context)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-300 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-t-2xl overflow-hidden flex flex-col absolute right-10vw bottom-0 h-60vh bg-black-850 w-full max-w-sm transition duration-300 transform ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <button
          onClick={() => {
            setShowModal(false)
          }}
          className='absolute z-20 top-0 right-0 m-3 group flex h-10 w-10 rounded-full bg-black-750 hover:bg-black-725 text-black-200 hover:text-white'>
          <XMarkIcon strokeWidth={2} className='transition duration-200 group-hover:rotate-90 h-6 w-6 m-auto' />
        </button>
        <div className='flex-1 overflow-scroll'>
          <p className='text-primary font-bold text-lg px-3 py-6'>Tasks</p>
          <div className='px-2 pb-8'>
            {list &&
              <ul className='space-y-3'>
                {list.map(doc => {
                  let progress = 0
                  let duration = 0
                  switch(doc.status.value) {
                    case 'Created':
                      progress = doc.progress
                      duration = doc.progress.toFixed(2) + '%'
                      break
                    case 'Pushing':
                      progress = doc.progress
                      duration = doc.progress.toFixed(2) + '%'
                      break
                    case 'Error':
                      break
                    case 'Cancelled':
                      break
                    case 'Queued':
                      progress = 15
                      duration = doc.duration
                      break
                    case 'Processing':
                      progress = 33
                      duration = doc.duration
                      break
                    case 'Completed':
                      progress = 66
                      duration = doc.duration
                      break
                    case 'Built':
                      progress = 100
                      duration = doc.duration
                      break
                    default:
                      break
                  }
                  return (
                    <li key={doc.id + '-' + doc.type} className='bg-black-825 rounded py-6 px-2 fade-in flex items-center'>
                      <div className='flex-1'>
                        <div className='flex items-center gap-3 mb-3'>
                          <div className='bg-black-750 rounded-full overflow-hidden h-1 w-1/2'>
                            <div className='h-full bg-primary rounded-full transition-all duration-200' style={{ width: progress + '%'}} />
                          </div>
                          <p className='text-sm text-black-400'>{duration}</p>
                        </div>
                        <div className='flex'>
                          <div>
                            <p>{doc.status.value}</p>
                            {doc.status.description &&
                              <p className='text-sm text-black-400 mb-3'>{doc.status.description}</p>
                            }
                            {doc.status.errorMessage &&
                              <p className='text-sm text-black-400 mb-6'>{doc.status.errorMessage}</p>
                            }
                            <p className='text-sm'>{doc.name}</p>
                            <p className='text-sm text-black-400'>{doc.address}</p>
                          </div>
                        </div>
                      </div>
                      <p>
                        <Link
                          onClick={() => {
                            setShowModal(false)
                          }}
                          className='self-end group flex h-9 w-9 rounded-full text-center bg-black-725 hover:bg-black-700 transition duration-200' to={'projects/'+doc.id}>
                          <EyeIcon className='h-4 w-4 m-auto text-primary transition duration-200 group-hover:scale-125' />
                        </Link>
                      </p>
                    </li>
                  )}
                )}
              </ul>
            }
            {!list &&
              <p className='text-black-400'>No alerts.</p>
            }
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}
