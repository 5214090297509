import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { Context } from 'store/index'

import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'
import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { PencilIcon, CubeIcon, ClockIcon, VideoCameraIcon, TrashIcon, MapPinIcon, HomeModernIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { AnaglyphEffect } from 'three/examples/jsm/effects/AnaglyphEffect.js'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

const camFactor = 2

export default function Index({ project }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://flightpack-assets')

  const [state, dispatch] = useContext(Context)

  const mounted = useRef(null)
  const compassRef = useRef(null)
  const viewerRef = useRef(null)

  const camera = useRef(false)
  const scene = useRef(false)
  const controls = useRef(false)
  const object = useRef(false)
  const renderer = useRef(false)
  const animationFrame = useRef(false)
  const sun = useRef(false)
  const effect = useRef(false)

  useEffect(() => {
    mounted.current = true

    init()
		animate()

    return () => {
      mounted.current = null
      destroyThree()
    }
  }, [])

  function init() {
    initScene()
    initCamera()
    initLighting()
    initRenderer()
    initControls()
    initReszeEvents()

    let sphereGeometry = new THREE.SphereGeometry(1.5, 100, 100)
    let sphereMesh = new THREE.MeshStandardMaterial({ color: 'blue' })
    sun.current = new THREE.Mesh(sphereGeometry, sphereMesh)
    scene.current.add(sun.current)
  }

  function destroyThree() {
    window.removeEventListener('resize', onWindowResize, false)
    cancelAnimationFrame(animationFrame.current)

    scene.current = null
    camera.current = null
    renderer.current = null
    controls.current = null

    if(viewerRef.current) {
      while(viewerRef.current.lastChild) viewerRef.current.removeChild(viewerRef.current.lastChild)
    }
  }

  function initScene() {
    scene.current = new THREE.Scene()
  }

  function initCamera() {
    // camera.current = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.current = new THREE.OrthographicCamera(-window.innerWidth/camFactor, window.innerWidth/camFactor, window.innerHeight/camFactor, -window.innerHeight/camFactor, 1, 1000)
    camera.current.position.set(0, 6, 100)
    camera.current.zoom = 70
    camera.current.updateProjectionMatrix()
    scene.current.add(camera.current)
  }

  function initLighting() {
    scene.current.add(new THREE.AmbientLight(0xffffff))
    scene.current.add(new THREE.DirectionalLight('white', 2))
  }

  function initRenderer() {
    renderer.current = new THREE.WebGLRenderer({ antialias: true })
    renderer.current.setPixelRatio(window.devicePixelRatio)
    renderer.current.setSize(window.innerWidth, window.innerHeight)
    renderer.current.alpha = true
    renderer.current.setClearColor(0x000000, 0)
    renderer.current.outputEncoding = THREE.sRGBEncoding
    viewerRef.current.appendChild(renderer.current.domElement)

    // effect.current = new AnaglyphEffect(renderer.current)
		// effect.current.setSize(window.innerWidth, window.innerHeight)
  }

  function initControls() {
    controls.current = new OrbitControls(camera.current, renderer.current.domElement)

    controls.current.enableDamping = true
    controls.current.dampingFactor = 0.14
    controls.current.zoomSpeed = 0.14
    controls.current.rotateSpeed = 0.14

    controls.current.enableKeys = true
    controls.current.keyPanSpeed = 20.0
  }

  const onWindowResize = useCallback((event) => {
    // camera.current.aspect = window.innerWidth / window.innerHeight

    camera.current.left = -window.innerWidth / camFactor
    camera.current.right = window.innerWidth / camFactor
    camera.current.top = window.innerHeight / camFactor
    camera.current.bottom = -window.innerHeight / camFactor
    camera.current.updateProjectionMatrix()
    renderer.current.setSize(window.innerWidth, window.innerHeight)
    // effect.current.setSize(window.innerWidth, window.innerHeight)
  }, [])

  function initReszeEvents() {
    window.addEventListener('resize', onWindowResize, false)
  }

  let t = 0

  function animate() {
    animationFrame.current = requestAnimationFrame(animate)
    controls.current.update()

    t += 0.01
    sun.current.material.color.set(new THREE.Color(0x0000FF).lerp(new THREE.Color(0xFF0000), 0.5 * (Math.sin(t) + 1)))
    // scene.current.background = new THREE.Color(0x0000FF).lerp(new THREE.Color(0xFF0000), 0.5 * (Math.sin(t) + 1))

    console.log(camera.current)
    render()
  }

  function render() {
    renderer.current.render(scene.current, camera.current)
    // effect.current.render(scene.current, camera.current)
  }

  return (
    <div className='relative h-screen'>
      <div ref={viewerRef} className='fade-in relative h-full z-0' />
    </div>
  )
}
