import { useRef, useEffect, useState, useContext, useCallback } from 'react'

import { getApp } from 'firebase/app'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage"

import { Context } from 'store/index'

import Resizer from 'react-image-file-resizer'
import { useDropzone } from 'react-dropzone'

import { MapPinIcon } from '@heroicons/react/24/solid'

import Filter from './map-filter'

export default function Index({ uploads, setUploads }) {
  const resizeFile = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2048,
        2048,
        'jpg',
        100,
        0,
        (image) => {
          resolve(image)
        },
        'file'
      )
    })
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const firebaseApp = getApp()
    const storage = getStorage(firebaseApp, 'gs://flightpack-assets')

    const images = {}
    for(const file of acceptedFiles) {
      images[file.name] = {
        name: file.name
      }
    }

    setUploads({
      status: {
        value: 'Resizing',
        label: 'Resizing images...',
      },
      count: acceptedFiles.length,
      countResizeComplete: 0,
      countUploadComplete: 0,
      images: images
    })

    for(const file of acceptedFiles) {
      const image = await resizeFile(file)
      setUploads(prev => ({
        ...prev,
        countResizeComplete: prev.countResizeComplete + 1,
        images: {
          ...prev.images,
          [file.name]: {
            image: image,
            name: file.name,
            url: URL.createObjectURL(image),
            progress: 0
          }
        }
      }))
      images[file.name] = {
        image: image,
        name: file.name,
        url: URL.createObjectURL(image),
        progress: 0
      }
    }

    setUploads(prev => ({
      ...prev,
      status: {
        value: 'Pushing',
        label: 'Pushing to cloud...'
      },
    }))

    for(const image of Object.values(images)) {
      const storageRef = ref(storage, 'site-analysis/test/_raw/' + image.name)
      const uploadTask = uploadBytesResumable(storageRef, image.image)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')

          setUploads(prev => ({
            ...prev,
            images: {
              ...prev.images,
              [image.name]: {
                ...prev.images[image.name],
                progress: progress
              }
            }
          }))

          switch(snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break;
            case 'running':
              console.log('Upload is running')
              break;
          }
        },
        (error) => { },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploads(prev => ({
              ...prev,
              countUploadComplete: prev.countUploadComplete + 1,
              images: {
                ...prev.images,
                [image.name]: {
                  ...prev.images[image.name],
                  downloadURL: downloadURL
                }
              }
            }))
          })
        }
      )
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div>
      {!uploads &&
        <div {...getRootProps()} className='aspect-16-7 gap-6 w-96 text-center rounded-2xl transition duration-200 bg-black-875 hover:bg-black-850 flex flex-col items-center justify-center text-sm'>
          <input {...getInputProps()}
            webkitdirectory='true'
            mozdirectory='true'
            directory='true'
            multiple={true}
          />
          <MapPinIcon className='h-5 w-5 -mt-2 text-primary' />
          {isDragActive ?
            <p>Drop when you're ready...</p> :
            <p>Drop your rendered Orthographic Tiles<br />here to view on map.</p>
          }
        </div>
      }
      {uploads &&
        <div className='gap-6 w-96 text-center rounded-2xl flex flex-col items-center justify-center text-sm'>
          <div className='flex gap-6'>
            <div>
              <p>Resize</p>
              <p>{uploads.countResizeComplete / uploads.count * 100}%</p>
              <p>{uploads.countResizeComplete} / {uploads.count}</p>
            </div>
            <div>
              <p>Upload</p>
              <p>{Object.values(uploads.images).reduce((a,c) => a + (c.progress || 0), 0) / (uploads.count * 100) * 100}%</p>
              <p>{uploads.countUploadComplete} / {uploads.count}</p>
            </div>
          </div>
          <div>
            <p>{uploads.status.value}</p>
            <p>{uploads.status.label}</p>
          </div>
        </div>
      }
    </div>
  )
}
