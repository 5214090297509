import { useState, useRef, useEffect, useContext } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { Context } from 'store/index'

export default function Index({ map, type, setType }) {
  const [state] = useContext(Context)

  const ref = useRef(null)

  return (
    <div className='text-xs z-10 fixed bottom-10vh left-5vw flex items-center'>
      <ul className='flex gap-1'>
        <li>
          <button
            className={'border border-black-925 rounded-full px-4 py-1 flex gap-3 items-center transition duration-200 ' + (type === 'blank' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100 hover:bg-black-750')}
            onClick={() => setType('blank')}>
            Blank
          </button>
        </li>
        <li>
          <button
            className={'border border-black-925 rounded-full px-4 py-1 flex gap-3 items-center transition duration-200 ' + (type === 'satellite-bing' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100 hover:bg-black-750')}
            onClick={() => setType('satellite-bing')}>
            Bing
          </button>
        </li>
        <li>
          <button
            className={'border border-black-925 rounded-full px-4 py-1 flex gap-3 items-center transition duration-200 ' + (type === 'satellite-mapbox' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100 hover:bg-black-750')}
            onClick={() => setType('satellite-mapbox')}>
            Mapbox
          </button>
        </li>
        <li>
          <button
            className={'border border-black-925 rounded-full px-4 py-1 flex gap-3 items-center transition duration-200 ' + (type === 'satellite-google' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100 hover:bg-black-750')}
            onClick={() => setType('satellite-google')}>
            Google
          </button>
        </li>
        {/*
        <li>
          <button
            className={'rounded-full px-4 py-1 flex gap-3 items-center ' + (type === 'satellite-usgs' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100')}
            onClick={() => setType('satellite-usgs')}>
            USGS
          </button>
        </li>
        <li>
          <button
            className={'rounded-full px-4 py-1 flex gap-3 items-center ' + (type === 'satellite-arcgis' ? 'bg-primary  text-black' : 'bg-black-800 text-black-100')}
            onClick={() => setType('satellite-arcgis')}>
            ArcGIS
          </button>
        </li>
        */}
      </ul>
    </div>
  )
}
