import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'
import { getAddress } from 'lib/utils'

import { Context } from 'store/index'

import { Cog8ToothIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import slugify from 'slugify'

export default function Index({ project }) {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)

  return (
    <div>
      <button
        onClick={() => setShow(!show)}
        className='mt-4 transition duration-200 pl-2 pr-3 py-1 rounded-full flex gap-1.5 bg-black-775 hover:bg-black-750'>
        <Cog8ToothIcon className='h-3.5 w-3.5 m-auto group-hover:scale-125 transition duration-200 text-primary' />
        <span className='text-xxs'>
          Settings
        </span>
      </button>

      <Modal showModal={show} setShowModal={setShow} project={project} />
    </div>
  )
}

function Modal({ showModal, setShowModal, project }) {
  const navigate = useNavigate()

  const [state, dispatch] = useContext(Context)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'h-full max-h-80vh mt-20vh mx-auto w-full max-w-sm transform transition duration-500 ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <div className='rounded-t-2xl relative overflow-scroll flex flex-col h-full bg-black-850 w-full max-w-md mx-auto'>
          <div className='absolute top-0 right-0 m-3'>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className='group flex h-10 w-10 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
              <XMarkIcon strokeWidth={2} className='transition duration-200 group-hover:rotate-90 h-6 w-6 m-auto' />
            </button>
          </div>

          <div className='p-3 py-6'>
            <h1 className='text-primary text-lg font-bold pb-12'>Project Settings</h1>

          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange, placeholder }) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}
