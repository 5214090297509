import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'
import { getAddress } from 'lib/utils'

import { Context } from 'store/index'

import { CubeIcon, UserIcon } from '@heroicons/react/24/solid'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'

import slugify from 'slugify'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)
  const [currentContact, setCurrentContact] = useState(false)

  useEffect(() => {
    setCurrentContact(state.currentContact)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentContact])

  return (
    <div>
      <button
        onClick={() => setShow(!show)}
        className='group bg-black-825 hover:bg-black-800 text-primary flex h-10 w-10 transition duration-200 rounded-full'>
        <PlusIcon className='transition duration-200 group-hover:scale-110 group-hover:rotate-90 h-5 w-5 m-auto' strokeWidth={2.75} />
      </button>

      <Modal showModal={show} setShowModal={setShow} currentContact={currentContact} setCurrentContact={setCurrentContact} />
    </div>
  )
}

function Modal({ showModal, setShowModal, currentContact, setCurrentContact }) {
  const navigate = useNavigate()

  const [state, dispatch] = useContext(Context)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)
  const contactsUnsub = useRef(false)

  const [search, setSearch] = useState('')

  const [data, setData] = useState(false)
  const [page, setPage] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [isPaging, setIsPaging] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(contactsUnsub.current) contactsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(search) debouncedGetContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedGetContacts = useDebouncedCallback(() => {
    getContacts()
  }, 1000)

  useEffect(() => {
    if(search && search !== '') {
      debouncedGetContacts()
    } else if(search !== false) {
      if(contactsUnsub.current) contactsUnsub.current()
      setContacts(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  async function getContacts() {
    if(contactsUnsub.current) contactsUnsub.current()

    let q = null
    if(search && search !== '') {
      q = query(collection(db, 'contacts'), orderBy('createdAt', 'desc'), where('keyWords', 'array-contains', search.toLowerCase()), limit(25))
    } else {
      q = query(collection(db, 'contacts'), orderBy('createdAt', 'desc'), limit(25))
    }

    contactsUnsub.current = onSnapshot(q, (querySnapshot) => {
      if(mounted.current) {
        if(!querySnapshot.empty) {
          setContacts(querySnapshot.docs.map(doc => {
            const c = {
              id: doc.id
            }
            Object.entries(doc.data()).forEach(([key, value]) => {
              if(value instanceof Timestamp) {
                c[key] = value.seconds * 1000 + value.nanoseconds / 1000000
              } else {
                c[key] = value
              }
            })
            return {
              vid: doc.id,
              ...c
            }
          }))
          setPage({
            hasMore: true,
            vidOffset: querySnapshot.docs[querySnapshot.docs.length - 1].data().createdAt.toDate()
          })
        } else {
          setContacts(null)
        }
      } else {
        setContacts(null)
      }
    })
  }

  async function loadMore() {
    setIsPaging(true)
  }

  function isComplete() {
    return data && data.title
  }

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
          setCurrentContact(false)
          setData(false)
          setSearch(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'h-full max-h-80vh mt-20vh mx-auto w-full max-w-sm transform transition duration-500 ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <div className='rounded-t-2xl relative overflow-scroll flex flex-col h-full bg-black-850 w-full max-w-md mx-auto'>
          <div className='absolute top-0 right-0 m-3'>
            <button
              onClick={() => {
                setShowModal(false)
                setCurrentContact(false)
                setData(false)
                setSearch('')
              }}
              className='group flex h-10 w-10 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
              <XMarkIcon strokeWidth={2} className='transition duration-200 group-hover:rotate-90 h-6 w-6 m-auto' />
            </button>
          </div>

          <div className='p-3 py-6'>
            <h1 className='text-primary text-lg font-bold pb-12'>New Project</h1>
            {!currentContact &&
              <div className='fade-in'>
                <p className='pb-6 text-sm max-w-xs'>Search for a contact so we can link their account and address to this project.</p>
                <div className='relative'>
                  <input
                    autoComplete='off'
                    id='search'
                    className='bg-black-800 rounded-lg focus:ring-2 focus:ring-primary transition duration-200 appearance-none w-full py-3 px-3 text-sm placeholder-black-300 focus:outline-none'
                    type='text'
                    placeholder='Search'
                    value={search}
                    onChange={(e) => {
                      setContacts(false)
                      setSearch(e.target.value)
                    }}
                  />
                  {search !== '' &&
                    <button onClick={() => setSearch('')} className='group absolute right-2 transition duration-200 top-1/2 transform -translate-y-1/2 h-5 w-5 rounded-full hover:brightness-110 flex items-center justify-center bg-primary text-black'>
                      <XMarkIcon strokeWidth={2.5} className='transition duration-200 group-hover:rotate-90 h-3.5 w-3.5' />
                    </button>
                  }
                </div>
              </div>
            }
          </div>
          {!currentContact &&
            <div className='fade-in'>
              {contacts === false && search && search !== '' &&
                <div className='py-18 flex'>
                  <div className='flex h-32 w-32 relative m-auto'>
                    {search && search !== '' && <span className='z-0 bg-black-825 h-full w-full animate-ping absolute top-0 left-0 rounded-full m-auto flex' />}
                    <UserIcon className='relative z-10 text-primary h-6 w-6 m-auto' />
                  </div>
                </div>
              }
              {contacts === null &&
                <div data-aos='fade-in' className='py-6 w-full relative'>
                  <div className='flex'>
                    <p className='bg-black-825 rounded-2xl text-center flex-1 max-w-xs p-3 m-auto'>
                      No contacts found.
                    </p>
                  </div>
                </div>
              }
              {contacts &&
                <div data-aos='fade-in'>
                  <ul className='pt-12 px-3'>
                    {contacts.map(contact => {
                      const name = (contact.firstname || contact.lastname) ? (contact.firstname ? contact.firstname.toLowerCase() + ' ' : '') + (contact.lastname ? contact.lastname.toLowerCase() : '') : false
                      const callDate = contact.last_call_date ? parseInt(contact.last_call_date) + (new Date().getTimezoneOffset() * 60000) : false

                      const filterServiceArea = state.contactProperties.service_area && contact.service_area ? state.contactProperties.service_area.filter(option => option.value === contact.service_area) : []
                      const serviceArea = filterServiceArea.length > 0 ? filterServiceArea[0].label : false

                      const address = getAddress(contact, <span className='text-black-500'>&middot;&middot;&middot;</span>)

                      return (
                        <li key={'list-'+contact.vid} className='fade-in text-sm relative w-full flex pr-4 py-2 space-x-3 items-center'>
                          <button
                            onClick={() => {
                              setCurrentContact(contact)
                              console.log(contact)
                              setData({
                                title: contact.address
                              })
                            }}
                            className='shrink-0 group h-9 w-9 rounded-full transition duration-200 bg-black-775 hover:bg-black-750 flex'>
                            <PlusIcon className='text-primary transition duration-200 group-hover:scale-125 h-4 w-4 m-auto' strokeWidth={2.5} />
                          </button>
                          <span className='flex-1 overflow-hidden'>
                            <span className='truncate block'>
                              {name ?
                                <span className='capitalize'>{name}</span> :
                                <span className='text-black-400'>&middot;&middot;&middot;</span>
                              }
                            </span>
                            <span className='truncate block text-xs text-black-300'>{address}</span>
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                  <div className='py-12'>
                    {search === '' && page.hasMore &&
                      <>
                        {!isPaging &&
                          <button
                            onClick={() => loadMore()}
                            className='block m-auto bg-black-800 px-6 py-1.5 text-sm rounded-full text-black-200 hover:bg-black-800 hover:text-white active:bg-black-775'>
                            Load More
                          </button>
                        }
                        {isPaging &&
                          <span className='py-2 text-sm block text-center text-black-300'>
                            Loading more contacts...
                          </span>
                        }
                      </>
                    }
                    {!page.hasMore &&
                      <span className='block px-3 py-16 text-black-400 uppercase text-xs'>
                        There are no more contacts in this list.
                      </span>
                    }
                  </div>
                </div>
              }
            </div>
          }
          {currentContact &&
            <div className='fade-in px-3'>
              <div>
                <p className='text-black-400 text-xs pb-3'>Contact Selected</p>
                <p className=''>{(currentContact.firstname || currentContact.lastname) ? (currentContact.firstname ? currentContact.firstname + ' ' : '') + (currentContact.lastname ? currentContact.lastname : '') : 'No name'}</p>
                <p className='text-black-400 text-sm mb-3'>{currentContact.formatted_address ? currentContact.formatted_address : 'No address'}</p>
                <button
                  onClick={() => {
                    setCurrentContact(false)
                    setData(false)
                  }}
                  className='text-xs font-bold group shrink-0 bg-black-775 transition duration-200 hover:bg-black-750 text-black-100 flex rounded-full px-3 py-1'>
                  Remove
                </button>
              </div>
              <div className='space-y-2 py-12'>
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))
                }} data={data} property='title' label='Title' placeholder='Enter title' />
              </div>
              <div className='text-sm absolute bottom-0 left-0 my-6 mx-3 flex z-50 justify-between'>
                <div className='flex gap-1'>
                  <button
                    onClick={async () => {
                      console.log('add')
                      const update = {
                        full_address: getAddress(currentContact, 'No address'),
                        name: currentContact.firstname + ' ' + currentContact.lastname,
                        uid: state.user.uid
                      }
                      if(currentContact.address) update.address = currentContact.address
                      if(currentContact.lat) update.lat = currentContact.lat
                      if(currentContact.lng) update.lng = currentContact.lng
                      if(currentContact.id) update.cid = currentContact.id

                      const docRef = await addDoc(collection(db, 'site-analysis'), {
                        ...data,
                        ...update,
                        status: {
                          value: 'Created',
                          description: 'This project has been created.'
                        },
                        updatedAt: new Date(),
                        createdAt: new Date()
                      }, { merge: true })

                      navigate('projects/'+docRef.id)

                      setShowModal(false)

                      setCurrentContact(false)
                      setData(false)
                      setSearch('')
                    }}
                    disabled={isComplete() ? false : true}
                    className={'hover:brightness-110 font-bold transition duration-200 px-4 py-1 rounded-lg ' + (isComplete() ? 'bg-primary text-black' : 'text-black-400 bg-black-775')}>
                    Create
                  </button>
                </div>
              </div>
            </div>
          }
        </div>

      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange, placeholder }) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}
