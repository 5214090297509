import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { Context } from 'store/index'

import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'
import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import convert from 'convert-units'

import * as THREE from 'three'

import * as PCL from 'pcl.js'

import { v4 as uuidv4 } from 'uuid'
import { useDropzone } from 'react-dropzone'

import { PencilIcon, CubeIcon, ClockIcon, VideoCameraIcon, TrashIcon, MapPinIcon, HomeModernIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js'
// import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'

import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer'

export default function Index({ project }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://flightpack-assets')

  const [state, dispatch] = useContext(Context)

  const mounted = useRef(null)
  const compassRef = useRef(null)
  const viewerRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [view, setView] = useState(false)
  const [cameraAngle, setCameraAngle] = useState('free')

  const [keyCommand, setKeyCommand] = useState(false)

  const [mtlPath, setMtlPath] = useState(false)
  const [objPath, setObjPath] = useState(false)
  const [numFiles, setNumFiles] = useState(false)
  const [filePaths, setFilePaths] = useState({})
  const [progress, setProgress] = useState({
    material: 0,
    model: 0
  })

  const camera = useRef(false)
  const cameraOrtho = useRef(false)
  const labelRenderer = useRef(false)
  const scene = useRef(false)
  const controls = useRef(false)
  const object = useRef(false)
  const renderer = useRef(false)
  const animationFrame = useRef(false)
  const matLineRef = useRef(new LineMaterial({
    color: 0x3388ff,
    linewidth: 3,
    vertexColors: true,
    dashed: false
  }))
  const matLineDashedRef = useRef(new LineMaterial({
    color: 0x3388ff,
    linewidth: 3,
    vertexColors: true,
    dashed: false,
    // dashed: true,
    // dashOffset: 0,
    // dashScale: 2,
    // gapSize: 1,
    // dashSize: 2
  }))
  // matLineDashedRef.current.defines.USE_DASH = ''

  const ctrlDownRef = useRef(false)
  const keyDownRef = useRef(false)
  const drawingLineRef = useRef(false)
  const [drawingLine, setDrawingLine] = useState(false)
  const [drawingActive, setDrawingActive] = useState(false)

  const lineIdRef = useRef(uuidv4())
  const lineStartRef = useRef(false)
  const lineRef = useRef(false) // threejs scene ref
  const [lineActive, setLineActive] = useState(false)
  const prevLineActive = useRef(false)

  const [measurements, setMeasurements] = useState([])
  const measurementsRef = useRef({}) // threejs scene ref
  const measurementLabelsRef = useRef({}) // threejs scene ref
  const [saveMeasurements, setSaveMeasurements] = useState(false)

  const pickableObjects = useRef([])

  const raycaster = useRef(new THREE.Raycaster())
  const intersects = useRef([])
  const mouse = useRef(new THREE.Vector2())

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = null
    }
  }, [])

  function removeMeasurement(m) {
    scene.current.remove(measurementLabelsRef.current[m.id])
    scene.current.remove(measurementsRef.current[m.id])
    setMeasurements(prev => prev.filter(p => p.id !== m.id))
    setSaveMeasurements(true)
  }

  function centerObject(o) {
    const box = new THREE.Box3().setFromObject(o)
    const center = new THREE.Vector3()
    box.getCenter(center)
    o.position.sub(center)
  }

  function setCameraInitial() {
    // controls.current.reset()

    // camera.current.position.set(30,30,30)
    // controls.current.target.set(0,-15,0)

    new TWEEN.Tween(camera.current.position).to(
      {
        x: 30,
        y: 30,
        z: 30,
      },
      500
    )
    .easing(TWEEN.Easing.Cubic.Out)
    .start()

    new TWEEN.Tween(controls.current.target).to(
      {
        x: 0,
        y: -15,
        z: 0,
      },
      500
    )
    .easing(TWEEN.Easing.Cubic.Out)
    .start()

    // controls.current.update()
  }

  function setCameraTop() {
    controls.current.reset()

    camera.current.position.set(0,60,0)
    controls.current.target.set(0,0,0)

    controls.current.update()
    setCameraAngle('top')
  }

  function setCameraFront() {
    controls.current.reset()

    camera.current.position.set(0,30,40)
    controls.current.target.set(0,-15,0)

    controls.current.update()
    setCameraAngle('front')
  }

  function setCameraBack() {
    controls.current.reset()

    camera.current.position.set(0,30,-40)
    controls.current.target.set(0,-15,0)

    controls.current.update()
    setCameraAngle('back')
  }

  function setCameraLeft() {
    controls.current.reset()

    camera.current.position.set(-40,30,0)
    controls.current.target.set(0,-15,0)

    controls.current.update()
    setCameraAngle('left')
  }

  function setCameraRight() {
    controls.current.reset()

    camera.current.position.set(40,30,0)
    controls.current.target.set(0,-15,0)

    controls.current.update()
    setCameraAngle('right')
  }

  function removeMeasurements() {
    Object.values(measurementsRef.current).forEach(m => scene.current.remove(m))
    measurementsRef.current = {}
    Object.values(measurementLabelsRef.current).forEach(m => scene.current.remove(m))
    measurementLabelsRef.current = {}
  }

  function clearMeasurements() {
    if(window.confirm('Remove all measurements?')) {
      removeMeasurements()
      setMeasurements([])
      setSaveMeasurements(true)
    }
  }

  function destroyThree() {
    window.removeEventListener('keydown', keyDown, false)
    window.removeEventListener('keyup', keyUp, false)
    window.removeEventListener('resize', onWindowResize, false)

    document.removeEventListener('mousemove', onDocumentMouseMove, false)

    cancelAnimationFrame(animationFrame.current)
    // this.renderer.domElement.addEventListener('dblclick', null, false)

    removeMeasurements()
    measurementsRef.current = false

    if(measurementLabelsRef.current) {
      Object.values(measurementLabelsRef.current).forEach(l => {
        l.element.removeEventListener('pointerdown', labelPointerDown, false)
      })
    }
    measurementLabelsRef.current = false

    scene.current = null
    camera.current = null

    renderer.current = null

    controls.current.removeEventListener('change', controlsChange, false)
    controls.current = null

    labelRenderer.current.domElement.removeEventListener('pointerdown', onClick, false)
    labelRenderer.current.domElement.removeEventListener('dblclick', onDoubleClick, false)
    document.body.removeChild(labelRenderer.current.domElement)
    labelRenderer.current = null

    if(viewerRef.current) {
      while(viewerRef.current.lastChild) viewerRef.current.removeChild(viewerRef.current.lastChild)
    }

    setView(false)
    setLoaded(false)
  }

  function initScene() {
    // if(scene.current) destroyThree(scene.current)
    // if(!scene.current) scene.current = new THREE.Scene()
    scene.current = new THREE.Scene()
  }

  function initCamera() {
    // if(!camera.current) camera.current = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 1000 )
    // camera.current = new THREE.OrthographicCamera(window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000)
    // camera.current.up = new THREE.Vector3(0,1,0)

    camera.current = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000)
    camera.current.position.set(30,30,30)
    scene.current.add(camera.current)

    // activeCamera.current = camera.current
  }

  function initLighting() {
    scene.current.add(new THREE.AmbientLight(0xffffff))
  }

  function initRenderer() {
    // if(!renderer.current) renderer.current = new THREE.WebGLRenderer( { antialias: true } )
    renderer.current = new THREE.WebGLRenderer({ antialias: true })
    renderer.current.setPixelRatio(window.devicePixelRatio)
    renderer.current.setSize(window.innerWidth, window.innerHeight)
    renderer.current.alpha = true
    renderer.current.setClearColor(0x000000, 0)
    renderer.current.outputEncoding = THREE.sRGBEncoding
    viewerRef.current.appendChild(renderer.current.domElement)
  }

  const controlsChange  = useCallback((event) => {
    setCameraAngle('free')
  }, [])

  function initControls() {
    // if(!controls.current) controls.current = new OrbitControls(camera.current, renderer.current.domElement)
    controls.current = new OrbitControls(camera.current, labelRenderer.current.domElement)

    controls.current.enableDamping = true
    controls.current.dampingFactor = 0.14
    controls.current.zoomSpeed = 0.14
    controls.current.rotateSpeed = 0.14

    controls.current.enableKeys = true
    controls.current.keyPanSpeed = 20.0

    controls.current.listenToKeyEvents(window)
    controls.current.keys = {
      LEFT: 'ArrowRight',
      UP: 'ArrowUp',
      RIGHT: 'ArrowLeft',
      BOTTOM: 'ArrowDown'
    }

    controls.current.target.set(0,-15,0)

    controls.current.addEventListener('change', controlsChange, false)
  }

  function initModel() {
    const manager = new THREE.LoadingManager()
    const objectURLs = []

    manager.setURLModifier((url) => {
      // console.log(url)
      const file = filePaths[url.replace('./','')]
      // console.log(file)
      url = file.url
      objectURLs.push(url)
      return url
    })

    const mtlLoader = new MTLLoader(manager)
    const objLoader = new OBJLoader(manager)

    function onProgress(xhr, type) {
      if(xhr.lengthComputable) {
        const percentComplete = xhr.loaded / xhr.total * 100
        // console.log( type + ' ' + Math.round( percentComplete, 2 ) + '% loaded' )
        setProgress(prev => ({
          ...prev,
          [type]: percentComplete
        }))
      }
    }

    function onError(e) {
      console.log(e)
    }

    // mtlLoader.load('/models/obj/odm_textured_model_geo.mtl', function(materials) {
    mtlLoader.load(mtlPath, (materials) => {
      materials.preload()
      objLoader.setMaterials(materials)
      // objLoader.load('/models/obj/odm_textured_model_geo.obj', function(obj) {
      objLoader.load(objPath, (obj) => {
        if(mounted.current) {
          obj.rotateX(-Math.PI/2)
          centerObject(obj)
          obj.traverse((node) => {
            if(node.isMesh) {
              node.material.smoothShading = true
              pickableObjects.current.push(node)
            }
          })
          object.current = obj
          scene.current.add(obj)
          initMeasurements()
          setView(true)
        }
        // objectURLs.forEach((url) => URL.revokeObjectURL(url))
      }, (e) => onProgress(e, 'model'), onError)
    }, (e) => onProgress(e, 'material'), onError)

    // await PCL.init()
    // PCL.loadPCDFile('/models/pcd', PCL.PointXYZ)
  }

  function initLabelRenderer() {
    // if(!labelRenderer.current) labelRenderer.current = new CSS2DRenderer()
    labelRenderer.current = new CSS2DRenderer()
    labelRenderer.current.setSize(window.innerWidth, window.innerHeight)
    labelRenderer.current.domElement.style.position = 'absolute'
    labelRenderer.current.domElement.style.top = '0px'
    // labelRenderer.current.domElement.style.pointerEvents = 'none'
    document.body.appendChild(labelRenderer.current.domElement)
  }

  function startDrawing() {
    setDrawingActive(true)
    setKeyCommand('Drawing distance measurement')
    ctrlDownRef.current = true
    controls.current.enabled = false
    labelRenderer.current.domElement.style.cursor = 'crosshair'
  }

  const keyDown = useCallback((event) => {
    if(event.key === 'f') {
      keyDownRef.current = true
      startDrawing()
    }
  }, [])

  function endDrawing() {
    setKeyCommand(false)
    setDrawingActive(false)
    ctrlDownRef.current = false
    controls.current.enabled = true
    labelRenderer.current.domElement.style.cursor = 'pointer'

    if(drawingLineRef.current) {
      //delete the last line because it wasn't committed
      scene.current.remove(lineRef.current)
      scene.current.remove(measurementLabelsRef.current[lineIdRef.current])
    }
    drawingLineRef.current = false
    setDrawingLine(false)
  }

  const keyUp = useCallback((event) => {
    if(event.key === 'f') {
      keyDownRef.current = false
      endDrawing()
    }
  }, [])

  function initDrawingKeyCommands() {
    window.addEventListener('keydown', keyDown, false)
    window.addEventListener('keyup', keyUp, false)
  }

  async function doSaveMeasurements() {
    setDoc(doc(db, 'site-analysis', project.id), {
      measurements: measurements,
      updatedAt: new Date()
    }, { merge: true })
  }

  const labelPointerDown = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    if(window.confirm('Remove measurement?')) {
      const id = event.target.closest('div').dataset.id
      removeMeasurement({ id: id })
    }
  }, [])

  const onDoubleClick = useCallback((event) => {
    raycaster.current.setFromCamera(mouse.current, camera.current)
    const intersects = raycaster.current.intersectObjects(pickableObjects.current, false)

    if(intersects.length > 0) {
      const newCameraPosition = new THREE.Vector3()
      const pCamera = newCameraPosition.lerpVectors(camera.current.position, intersects[0].point, 0.5)

      new TWEEN.Tween(camera.current.position).to(
        {
          x: pCamera.x,
          y: pCamera.y,
          z: pCamera.z
        },
        500
      )
      .easing(TWEEN.Easing.Cubic.Out)
      .start()

      const newControlsTarget = new THREE.Vector3()
      const pControls = newControlsTarget.lerpVectors(controls.current.target, intersects[0].point, 0.75)
      new TWEEN.Tween(controls.current.target).to(
        {
          x: pControls.x,
          y: pControls.y,
          z: pControls.z
        },
        500
      )
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
    }
  }, [])

  const onClick = useCallback((event) => {
    if(ctrlDownRef.current) {
      raycaster.current.setFromCamera(mouse.current, camera.current)
      intersects.current = raycaster.current.intersectObjects(pickableObjects.current, false)
      if(intersects.current.length > 0) {
        if(!drawingLineRef.current) {
          console.log('start')
          // draw line

          const colors = []
          const color = new THREE.Color('#3388ff')
          colors.push(color.r,color.g,color.b)
          colors.push(color.r,color.g,color.b)

          const positions = []
          positions.push(intersects.current[0].point.x,intersects.current[0].point.y,intersects.current[0].point.z)
          positions.push(intersects.current[0].point.x,intersects.current[0].point.y,intersects.current[0].point.z)

          const lineGeometry = new LineGeometry()
          lineGeometry.setPositions(positions)
          lineGeometry.setColors(colors)
          lineStartRef.current = [intersects.current[0].point.x,intersects.current[0].point.y,intersects.current[0].point.z]

          lineRef.current = new LineSegments2(
            lineGeometry,
            matLineDashedRef.current
          )

          lineRef.current.computeLineDistances()

          lineRef.current.frustumCulled = false
          lineRef.current.renderOrder = 999
          lineRef.current.material.depthTest = false
          lineRef.current.material.depthWrite = false
          lineRef.current.onBeforeRender = (renderer) => { renderer.clearDepth() }
          measurementsRef.current[lineIdRef.current] = lineRef.current
          scene.current.add(lineRef.current)

          //draw label

          const measurementDiv = document.createElement('div')
          measurementDiv.className = 'bg-black text-white rounded px-1 text-xxs font-body leading-tight py-1.5'

          const measurements = getLabelMeasurements(positions)
          const html = getLabelHTML(measurements)
          measurementDiv.innerHTML = html
          measurementDiv.dataset.id = lineIdRef.current

          const measurementLabel = new CSS2DObject(measurementDiv)
          measurementLabel.position.copy(intersects.current[0].point)
          measurementLabelsRef.current[lineIdRef.current] = measurementLabel

          scene.current.add(measurementLabelsRef.current[lineIdRef.current])
          drawingLineRef.current = true
          setDrawingLine(true)
        } else {
          //finish the line

          console.log('finish')

          const positions = []
          positions.push(lineStartRef.current[0],lineStartRef.current[1],lineStartRef.current[2])
          positions.push(intersects.current[0].point.x,intersects.current[0].point.y,intersects.current[0].point.z)

          lineRef.current.geometry.setPositions(positions)
          lineRef.current.material = matLineRef.current

          const measurements = getLabelMeasurements(positions)
          const html = getLabelHTML(measurements)

          measurementLabelsRef.current[lineIdRef.current].element.innerHTML = html
          measurementLabelsRef.current[lineIdRef.current].element.classList.add('group')
          measurementLabelsRef.current[lineIdRef.current].element.classList.add('cursor-pointer')
          measurementLabelsRef.current[lineIdRef.current].element.addEventListener('pointerdown', labelPointerDown, false)

          setMeasurements(prev => [
            ...prev,
            {
              createdAt: new Date().getTime(),
              id: lineIdRef.current,
              positions: positions,
              ...measurements
            }
          ])

          drawingLineRef.current = false
          lineRef.current = false
          lineStartRef.current = false

          setTimeout(() => {
            lineIdRef.current = uuidv4()
            if(!keyDownRef.current) endDrawing()
            setSaveMeasurements(true)
          }, 100)
        }
      }
    }
  }, [])

  const onDocumentMouseMove = useCallback((event) => {
    event.preventDefault()

    mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1

    if(drawingLineRef.current) {
      raycaster.current.setFromCamera(mouse.current, camera.current)
      intersects.current = raycaster.current.intersectObjects(pickableObjects.current, false)
      if(intersects.current.length > 0) {
        const v0 = new THREE.Vector3(
          lineStartRef.current[0],
          lineStartRef.current[1],
          lineStartRef.current[2]
        )
        const v1 = new THREE.Vector3(
          intersects.current[0].point.x,
          intersects.current[0].point.y,
          intersects.current[0].point.z
        )

        const positions = []
        positions.push(lineStartRef.current[0],lineStartRef.current[1],lineStartRef.current[2])
        positions.push(intersects.current[0].point.x,intersects.current[0].point.y,intersects.current[0].point.z)

        lineRef.current.geometry.setPositions(positions)
        lineRef.current.computeLineDistances()

        const measurements = getLabelMeasurements(positions)
        const html = getLabelHTML(measurements)

        measurementLabelsRef.current[lineIdRef.current].element.innerHTML = html
        measurementLabelsRef.current[lineIdRef.current].position.lerpVectors(v0, v1, 0.5)
        measurementLabelsRef.current[lineIdRef.current].length = measurements.length
        measurementLabelsRef.current[lineIdRef.current].azimuth = measurements.azimuth
        measurementLabelsRef.current[lineIdRef.current].height = measurements.height
        measurementLabelsRef.current[lineIdRef.current].slope = measurements.slope
      }
    }
  }, [])

  function initDrawingPointerEvents() {
    labelRenderer.current.domElement.addEventListener('pointerdown', onClick, false)
    labelRenderer.current.domElement.addEventListener('dblclick', onDoubleClick, false)
    document.addEventListener('mousemove', onDocumentMouseMove, false)
  }

  const onWindowResize = useCallback((event) => {
    camera.current.aspect = window.innerWidth / window.innerHeight
    camera.current.updateProjectionMatrix()
    renderer.current.setSize(window.innerWidth, window.innerHeight)
    labelRenderer.current.setSize(window.innerWidth, window.innerHeight)
  }, [])

  function initReszeEvents() {
    window.addEventListener('resize', onWindowResize, false)
  }

  function drawLine(line) {
    const colors = []
    const color = new THREE.Color('#3388ff')
    colors.push(color.r,color.g,color.b)
    colors.push(color.r,color.g,color.b)

    const lineGeometry = new LineGeometry()
    lineGeometry.setPositions(line.positions)
    lineGeometry.setColors(colors)

    const newLine = new LineSegments2(
      lineGeometry,
      matLineRef.current
    )

    newLine.frustumCulled = false
    newLine.renderOrder = 999
    newLine.material.depthTest = false
    newLine.material.depthWrite = false
    newLine.onBeforeRender = (renderer) => { renderer.clearDepth() }
    measurementsRef.current[line.id] = newLine
    scene.current.add(newLine)
  }

  function getLabelMeasurements(positions) {
    const v0 = new THREE.Vector3(
      positions[0],
      positions[1],
      positions[2]
    )
    const v1 = new THREE.Vector3(
      positions[3],
      positions[4],
      positions[5]
    )

    const metric = {
      height: Math.abs(positions[1] - positions[4]),
      length: v0.distanceTo(v1)
    }

    const standard = {
      height: convert(metric.height).from('m').to('ft'),
      length: convert(metric.length).from('m').to('ft')
    }

    const slope = ((Math.asin(metric.height / metric.length) * (180 / Math.PI)) || 0)

    let a1 = new THREE.Vector2(positions[0]-positions[3],positions[2]-positions[5]).angle()
    a1 = (a1)*180/Math.PI
    a1 = a1 - 90
    if(a1 < 0) a1 = a1 + 360
    a1 = a1

    let a2 = a1
    if(a1 < 180) {
      a2 = a1 + 180
    } else {
      a2 = a1 - 180
    }
    a2 = a2

    return {
      metric,
      standard,
      slope,
      azimuth: [a1, a2]
    }
  }

  function getLabelHTML(measurements) {
    let html = ''
    if(state.settings.units && state.settings.units === 'Metric') {
      html += '<p>' + convert(measurements.metric.length).from('m').to('mm').toLocaleString(undefined, { maximumFractionDigits: 2 }) + 'mm <span class=\'opacity-50\'>length</span></p>'
    } else {
      html += '<p>' + Math.trunc(measurements.standard.length) + '\' ' + convert(measurements.standard.length % 1).from('ft').to('in').toLocaleString(undefined, { maximumFractionDigits: 2 }) + '" <span class=\'opacity-50\'>length</span></p>'
    }
    html += '<p>' + (measurements.slope.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0) + '&deg; <span class=\'opacity-50\'>slope</span></p>'
    html += '<p class=\'hidden group-hover:block\'>' + getAzimuthDirection(measurements.azimuth[0]) + ' ' + measurements.azimuth[0].toLocaleString(undefined, { maximumFractionDigits: 2 }) + '&deg; ' + getAzimuthDirection(measurements.azimuth[1]) + ' ' + measurements.azimuth[1].toLocaleString(undefined, { maximumFractionDigits: 2 }) + '&deg; <span class=\'opacity-50\'>azimuth</span></p>'
    if(state.settings.units && state.settings.units === 'Metric') {
      html += '<p class=\'hidden group-hover:block\'>' + convert(measurements.metric.height).from('m').to('mm').toLocaleString(undefined, { maximumFractionDigits: 2 }) + 'mm <span class=\'opacity-50\'>height</span></p>'
    } else {
      html += '<p class=\'hidden group-hover:block\'>' + Math.trunc(measurements.standard.height) + '\' ' + convert(measurements.standard.height % 1).from('ft').to('in').toLocaleString(undefined, { maximumFractionDigits: 2 }) + '" <span class=\'opacity-50\'>height</span></p>'
    }

    return html
  }

  function drawLabel(line) {
    const positions = line.positions

    const measurementDiv = document.createElement('div')
    measurementDiv.className = 'cursor-pointer group bg-black text-white rounded px-1 text-xxs font-body leading-tight py-1.5'
    measurementDiv.dataset.id = line.id

    const v0 = new THREE.Vector3(
      positions[0],
      positions[1],
      positions[2]
    )
    const v1 = new THREE.Vector3(
      positions[3],
      positions[4],
      positions[5]
    )

    const measurementLabel = new CSS2DObject(measurementDiv)
    const measurements = getLabelMeasurements(positions)
    const html = getLabelHTML(measurements)
    measurementLabel.element.innerHTML = html
    measurementLabel.position.lerpVectors(v0, v1, 0.5)
    measurementLabelsRef.current[line.id] = measurementLabel

    measurementDiv.addEventListener('pointerdown', labelPointerDown, false)

    scene.current.add(measurementLabel)
  }

  function initMeasurements() {
    function drawMeasurements(toDraw) {
      toDraw.forEach((m) => {
        drawLine(m)
        drawLabel(m)
      })
    }

    if(project.measurements) {
      const initialLabels = {}
      if(project.measurements) {
        project.measurements.forEach(m => {
          initialLabels[m.id] = m
        })
      }

      lineIdRef.current = uuidv4()
      lineStartRef.current = false
      lineRef.current = false

      setMeasurements(project.measurements)
      measurementsRef.current = {}
      measurementLabelsRef.current = initialLabels

      drawMeasurements(project.measurements)
    } else {
      lineIdRef.current = uuidv4()
      lineStartRef.current = false
      lineRef.current = false

      setMeasurements([])
      measurementsRef.current = {}
      measurementLabelsRef.current = {}
    }
  }

  function animate() {
    animationFrame.current = requestAnimationFrame(animate)
    controls.current.update()
    TWEEN.update()
    matLineRef.current.resolution.set(window.innerWidth, window.innerHeight)
    matLineDashedRef.current.resolution.set(window.innerWidth, window.innerHeight)

    // const zoom = controls.current.target.distanceTo(controls.current.object.position)
    // const show = zoom > 35 ? false : true
    //
    // Object.values(measurementLabelsRef.current).forEach((o) => {
    //   o.visible = show
    //   // var scaleFactor = 8
    //   // var sprite = planet.children[0]
    //   // var scale = scaleVector.subVectors(planet.position, camera.position).length() / scaleFactor
    //   // sprite.scale.set(scale, scale, 1)
    //   // var orbit = planet.userData.orbit
    //   // var speed = planet.userData.speed
    //   // planet.position.x = Math.cos(timestamp * speed) * orbit
    //   // planet.position.z = Math.sin(timestamp * speed) * orbit
    //
    //   // const start = new THREE.Vector3()
    //   // start.copy(o.position)
    //   // const dist = start.distanceTo(camera.current.position)
    //   // const size = 1 / dist
    //   // o.element.style.transform = 'scale('+size+')'
    //   // console.log(o)
    // })

    const dir = new THREE.Vector3()
    const sph = new THREE.Spherical()
    camera.current.getWorldDirection(dir)
    sph.setFromVector3(dir)
    if(compassRef.current) compassRef.current.style.transform = `rotate(${THREE.Math.radToDeg(sph.theta) - 180}deg)`

    render()
  }

  function render() {
    labelRenderer.current.render(scene.current, camera.current)
    renderer.current.render(scene.current, camera.current)
  }

  useEffect(() => {
		async function init() {
      initScene()
      initCamera()
			initLighting()
      initRenderer()
      initLabelRenderer()
      initControls()
      initModel()
      initDrawingKeyCommands()
      initDrawingPointerEvents()
      initReszeEvents()
    }

    if(loaded) {
      init()
  		animate()
    }
  }, [loaded])

  function getAzimuthDirection(a) {

    const val = parseInt((a / 45) + .5)

    const arr = ["N","NE","E","SE","S","SW","W","NW"]
    return arr[(val % 8)]
  }

  useEffect(() => {
    async function getData() {
      const path = 'site-analysis/' + project.id
      const listRef = ref(storage, 'site-analysis/' + project.id)

      let mtl = false
      let obj = false
      const filePaths = {}

      listAll(listRef).then(async (res) => {
        res.prefixes.forEach((folderRef) => {
          if(folderRef._location.path === (path + '/odm_texturing')) {
            listAll(folderRef).then(async (fRes) => {
              setNumFiles(fRes.items.length)
              fRes.items.forEach((iRef) => {
                getDownloadURL(iRef).then(downloadURL => {
                  const type = iRef._location.path.split('.').pop()
                  const name = iRef._location.path.replace(/^.*[\\\/]/, '')
                  if(type === 'mtl') {
                    if(!mtl) {
                      setMtlPath(name)
                    }
                  } else if(type === 'obj') {
                    if(!obj) {
                      setObjPath(name)
                    }
                  }
                  setFilePaths(prev => ({
                    ...prev,
                    [name]: {
                      url: downloadURL
                    }
                  }))
                })
              })
            })
          }
        })

      }).catch(e => {
        console.log(e)
      })
    }
    getData()

    return () => {
      if(scene.current) destroyThree()
    }
  }, [])

  useEffect(() => {
    if(mtlPath && objPath && numFiles && filePaths && (numFiles === Object.keys(filePaths).length)) {
      setLoaded(true)
    }
  }, [mtlPath, objPath, numFiles, filePaths, loaded])

  useEffect(() => {
    if(saveMeasurements) {
      doSaveMeasurements()
      setSaveMeasurements(false)
    }
  }, [saveMeasurements])

  useEffect(() => {
    if(lineActive) {
      const element = measurementLabelsRef.current[lineActive].element
      element.classList.remove('bg-black')
      element.classList.remove('text-white')
      element.classList.add('bg-[#3388ff]')
      element.classList.add('text-black')
    }
    if(prevLineActive.current) {
      const element = measurementLabelsRef.current[prevLineActive.current].element
      element.classList.add('bg-black')
      element.classList.add('text-white')
      element.classList.remove('bg-[#3388ff]')
      element.classList.remove('text-black')
    }
    prevLineActive.current = lineActive
  }, [lineActive])

  return (
    <div className='relative h-screen'>
      {!view &&
        <div className='text-center fixed top-0 left-0 h-full w-full flex items-center justify-center'>
          {(Object.values(progress).reduce((a, c) => a + c)/(Object.keys(progress).length * 100) * 100) !== 100 &&
            <div className='fade-in gap-6 text-center flex flex-col items-center justify-center text-sm'>
              <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-850'>
                <ClockIcon className='animate-spin h-6 w-6 text-primary' />
              </div>
              <div>
                <p className='pb-1'>Loading...</p>
                <p className='text-xs text-black-400'>{(Object.values(progress).reduce((a, c) => a + c)/(Object.keys(progress).length * 100) * 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%</p>
              </div>
            </div>
          }
          {(Object.values(progress).reduce((a, c) => a + c)/(Object.keys(progress).length * 100) * 100) === 100 &&
            <div className='fade-in gap-6 text-center flex flex-col items-center justify-center text-sm'>
              <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-850'>
                <CubeIcon className='animate-spin h-6 w-6 text-primary' />
              </div>
              <div>
                <p className='pb-1'>Building...</p>
                <p className='text-xs text-black-400'>Hang tight! Your model is almost ready for viewing.</p>
              </div>
            </div>
          }
        </div>
      }

      <div ref={viewerRef} className='fade-in relative h-full z-0' />

      {view &&
        <>
          <div className='fade-left z-10 fixed right-0 bottom-10vh m-3'>
            <div ref={compassRef} className='shadow-lg border border-primary h-14 w-14 rounded-full relative bg-black-900 bg-opacity-60 backdrop-blur-lg text-primary font-mono'>
              <p className='absolute top-0 left-1/2 -translate-x-1/2 text-xxs font-bold p-1'>N</p>
              <p className='absolute bottom-0 left-1/2 -translate-x-1/2 text-xxs font-bold p-1'>S</p>
              <p className='absolute top-1/2 right-0 -translate-y-1/2 text-xxs font-bold p-1'>E</p>
              <p className='absolute top-1/2 left-0 -translate-y-1/2 text-xxs font-bold p-1'>W</p>
            </div>
          </div>

          {measurements && measurements.length > 0 &&
            <ul className='fade-in z-10 fixed bottom-0 left-0 m-3 max-h-50vh overflow-scroll text-xxs flex flex-col bg-black-850 divide-y rounded-lg'>
              {measurements.sort((a,b) => b.createdAt - a.createdAt).map((l, i) => {
                return (
                  <li
                    key={'measurement-' + l.id}
                    onMouseEnter={() => setLineActive(l.id)}
                    onMouseLeave={() => setLineActive(false)}
                    className='flex w-44 px-1.5 py-2 items-start fade-in hover:bg-[#3388ff] hover:text-black'>
                    <div className='flex-1'>
                      {state.settings.units && state.settings.units === 'Metric' ?
                        <p>{convert(l.metric.length).from('m').to('mm').toLocaleString(undefined, { maximumFractionDigits: 2 })}mm <span className='opacity-50'>length</span></p>
                        :
                        <p>{Math.trunc(l.standard.length)}' {convert(l.standard.length % 1).from('ft').to('in').toLocaleString(undefined, { maximumFractionDigits: 2 })}" <span className='opacity-50'>length</span></p>
                      }
                      <p>{l.slope.toLocaleString(undefined, { maximumFractionDigits: 2 })}&deg; <span className='opacity-50'>slope</span></p>
                      <p>{getAzimuthDirection(l.azimuth[0])} {l.azimuth[0].toLocaleString(undefined, { maximumFractionDigits: 2 })}&deg; {getAzimuthDirection(l.azimuth[1])} {l.azimuth[1].toLocaleString(undefined, { maximumFractionDigits: 2 })}&deg; <span className='opacity-50'>azimuth</span></p>
                      {state.settings.units && state.settings.units === 'Metric' ?
                        <p>{convert(l.metric.height).from('m').to('mm').toLocaleString(undefined, { maximumFractionDigits: 2 })}mm <span className='opacity-50'>height</span></p>
                        :
                        <p>{Math.trunc(l.standard.height)}' {convert(l.standard.height % 1).from('ft').to('in').toLocaleString(undefined, { maximumFractionDigits: 2 })}" <span className='opacity-50'>height</span></p>
                      }
                    </div>
                    <button
                      onClick={() => {
                        if(window.confirm('Remove measurement?')) {
                          removeMeasurement(l)
                        }
                      }}
                      className='shrink-0 group flex rounded-full'>
                      <XMarkIcon strokeWidth={3} className='transition duration-200 text-white opacity-50 group-hover:text-black group-hover:opacity-100 group-hover:rotate-90 h-3 w-3 m-auto' />
                    </button>
                  </li>
                )
              })}
            </ul>
          }

          <div className='fixed top-20vh max-h-70vh right-3 z-10 flex flex-col'>
            <div className='fade-left flex-1 flex flex-col items-end gap-1'>
              <button
                onClick={() => {
                  // setDrawingLine(!drawingLine)
                  if(drawingActive) {
                    endDrawing()
                  } else {
                    startDrawing()
                  }
                }}
                className={'group relative shrink-0 border border-black-925 group shadow-lg transition duration-200 h-10 w-10 rounded-full flex ' + (drawingActive ? 'bg-primary hover:brightness-110': 'bg-black-825 hover:bg-black-800')}>
                <span className='pointer-events-none leading-none py-1 mr-1 transition duration-300 opacity-0 group-hover:opacity-100 whitespace-nowrap text-xs rounded-full px-2 absolute top-1/2 transform -translate-y-1/2 right-full bg-primary text-black'>
                  Draw measure
                </span>
                <PencilIcon className={'h-4 w-4 m-auto group-hover:scale-125 transition duration-200 ' + (drawingActive ? 'text-black': 'text-primary')} />
              </button>
              <button onClick={clearMeasurements} className='group relative shrink-0 border border-black-925 group shadow-lg bg-black-825 hover:bg-black-800 transition duration-200 h-10 w-10 rounded-full flex'>
                <span className='pointer-events-none leading-none py-1 mr-1 transition duration-300 opacity-0 group-hover:opacity-100 whitespace-nowrap text-xs rounded-full px-2 absolute top-1/2 transform -translate-y-1/2 right-full bg-primary text-black'>
                  Remove all
                </span>
                <TrashIcon className='h-4 w-4 m-auto text-primary group-hover:scale-125 transition duration-200' />
              </button>
              <button onClick={setCameraInitial} className='group relative shrink-0 border border-black-925 group shadow-lg bg-black-825 hover:bg-black-800 transition duration-200 h-10 w-10 rounded-full flex'>
                <span className='pointer-events-none leading-none py-1 mr-1 transition duration-300 opacity-0 group-hover:opacity-100 whitespace-nowrap text-xs rounded-full px-2 absolute top-1/2 transform -translate-y-1/2 right-full bg-primary text-black'>
                  Reset view
                </span>
                <VideoCameraIcon className='h-4 w-4 m-auto text-primary group-hover:scale-125 transition duration-200' />
              </button>
            </div>
          </div>

          {/*
            <div data-aos='fade-in' className='absolute bottom-10vh left-5vw z-20'>
              {false && keyCommand &&
                <span className='fade-in block bg-[#3388ff] text-xs text-black mb-2 rounded bg-black px-2 py-1'>
                  {keyCommand}
                </span>
              }

              <div className='flex gap-1 h-10 items-center'>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'free' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={() => setCameraAngle('free')}>Ortho</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'top' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraTop}>Persp</button>
              </div>

              <div className='flex gap-1 h-10 items-center'>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'free' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={() => setCameraAngle('free')}>Free</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'top' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraTop}>Top</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'front' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraFront}>Front</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'left' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraLeft}>Left</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'right' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraRight}>Right</button>
                <button
                  className={'border border-black-925 text-xs transition duration-200 rounded-full px-4 py-1 shadow-lg ' + (cameraAngle === 'back' ? 'bg-primary text-black hover:brightness-110' : 'bg-black-800 text-black-100 hover:bg-black-750')}
                  onClick={setCameraBack}>Back</button>
              </div>
            </div>
          */}
        </>
      }
    </div>
  )
}
