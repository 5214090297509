import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { createPortal } from 'react-dom'

function Index({ children, onClose, center }, ref) {
  const [show, setShow] = useState(false)
  const [active, setActive] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false)
  }))

  useEffect(() => {
    if(active && show === false && onClose) onClose()
    if(show) setActive(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const trigger = children.find(child => child.props.trigger)
  const content = children.find(child => child.props.content)

  // 52288843
  // PNa189acde86204bfd004d51c550be40e5
  return (
    <>
      {trigger}
      {createPortal(show &&
        <>
          <button className='fixed top-3 right-3 text-black-100 hover:text-white transition duration-200 flex z-50' onClick={() => setShow(false)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
          <div className={'flex flex-col overflow-scroll fade-in bg-black bg-opacity-40 fixed top-0 inset-0 z-40 backdrop-filter backdrop-blur-md ' + (center ? 'justify-center' : '')}>
            <div className='fixed top-0 z-0 h-full w-full' onClick={() => setShow(false)} />

            <div className='py-10vh relative z-10 mx-auto'>
              {content}
            </div>
          </div>
        </>, document.body
      )}
    </>
  )
}

export default forwardRef(Index)
