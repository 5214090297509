import { useRef, useState, useCallback, useEffect, useContext } from 'react'

import { Context } from 'store/index'

import ReactCrop from 'react-image-crop'
import { useDropzone } from 'react-dropzone'

import Modal from 'components/modal'

export default function Index() {
  const [state] = useContext(Context)

  const pixelRatio = 4

  const imgModal = useRef(null)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  const [upImg, setUpImg] = useState(false)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [crop, setCrop] = useState({ unit: 'px', width: 260, aspect: 1/1 })

  const onSelectFile = files => {
    if (files && files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(files[0])
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement('canvas')
    tmpCanvas.width = newWidth
    tmpCanvas.height = newHeight
    const ctx = tmpCanvas.getContext('2d')
    ctx.drawImage(canvas,0, 0, canvas.width, canvas.height,0, 0, newWidth, newHeight)
    return tmpCanvas
  }

  function generateDownload(previewCanvas, crop, uploadImage) {
    if(!crop || !previewCanvas) return
    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height)
    canvas.toBlob(
      blob => {
        // const previewUrl = window.URL.createObjectURL(blob)
        uploadImage(blob)
        setUpImg(false)
      }, 'image/png', 1
    )
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)
  }, [completedCrop])

  const uploadImage = (file) => {
    if(file) {
      // storage.uploadImage(file, 'users/'+user.uid+'.jpg').then(image => {
      //   const docRef = doc(db, 'users', user.uid)
      //   updateDoc(docRef, {
      //     photo: image
      //   })
      //   // firestore.collection('users').doc(user.uid).update({ photo: image })
      // })
    }
  }

  return (
    <Modal ref={imgModal} center={true}>
      <button
        trigger='true'
        onClick={() => {
          imgModal.current.open()
        }}
        className='group relative block h-16 w-16 rounded-full bg-black-800 flex justify-center items-center'>
        {state.profile.photo ?
          <img className='h-full w-full rounded-full overflow-hidden' src={state.profile.photo.url} alt='Profile avatar.' /> :
          <svg xmlns='http://www.w3.org/2000/svg' className='text-primary h-6 w-6' viewBox='0 0 20 20' fill='currentColor'>
            <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
         </svg>
        }
        <div className='flex text-black border-2 ml-1 border-black-850 absolute top-1/2 left-full rounded-full h-6 w-6 bg-primary transition duration-200 group-hover:bg-yellow-500 group-hover:scale-110 transform -translate-x-1/2 -translate-y-1/2'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4 m-auto' viewBox='0 0 20 20' fill='currentColor'>
            <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
          </svg>
        </div>
      </button>
      <div content='true' className='w-full max-w-lg'>
        {!upImg && <MyDropzone onSelectFile={onSelectFile} />}
        {upImg &&
          <>
            <div className='flex items-center w-full'>
              <div className='w-2/3'>
                <ReactCrop
                  className='focus:outline-none relative rounded-lg overflow-hidden block'
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={c => setCrop(c)}
                  onComplete={c => setCompletedCrop(c)}
                />
              </div>
              <div className='flex-1 min-w-0 flex self-stretch flex-col justify-center items-center ml-4'>
                <canvas
                  className='mb-4 rounded-full overflow-hidden'
                  ref={previewCanvasRef}
                  style={{
                    width: 140,
                    height: 140
                  }}
                />
              </div>
            </div>
            <button
              className='mt-12 mx-auto btn-primary'
              type='button'
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() => {
                generateDownload(previewCanvasRef.current, completedCrop, uploadImage)
                imgModal.current.close()
              }}>
              Set Image
            </button>
          </>
        }
      </div>
    </Modal>
  )
}

function MyDropzone({ onSelectFile }) {
  const onDrop = useCallback(acceptedFiles => {
    onSelectFile(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className='flex justify-center py-6 px-20 pb-8 max-w-full w-96 cursor-pointer bg-black-800 rounded-xl hover:bg-black-775' {...getRootProps()}>
     <input {...getInputProps()} />
     <span className='flex w-full text-center justify-center items-center space-x-4 h-44'>
       {isDragActive ?
         'Drop the file here.' :
         <span>
            <span className='h-12 w-12 mb-6 mx-auto flex bg-black-700 rounded-full'>
              <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
              </svg>
            </span>
            <p className='text-lg leading-tight mb-6'>Drag 'n' drop a file here to<br />update your avatar.</p>
            <p className='text-sm text-black-300 leading-tight'>Or click here to choose a<br />file from your device.</p>
          </span>
       }
     </span>
   </div>
  )
}
