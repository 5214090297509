import { Routes, Route } from 'react-router-dom'

import Login from 'views/auth/login'
import Signup from 'views/auth/signup'
import Forgot from 'views/auth/forgot'

export default function Index() {
  return (
    <div className='fade-in'>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/forgot' element={<Forgot />} />
      </Routes>
    </div>
  )
}
