import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { Context } from 'store/index'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { CubeIcon } from '@heroicons/react/24/solid'

import TimeAgo from 'javascript-time-ago'

export default function Index({ project }) {
  const [state, dispatch] = useContext(Context)

  const timeAgo = new TimeAgo('en-US')

  const [duration, setDuration] = useState(timeAgo.format(project.startedAt, 'mini'))

  useEffect(() => {
    setInterval(() => {
      setDuration(timeAgo.format(project.startedAt, 'mini'))
    }, 1000)
  }, [])

  return (
    <div className='fade-in relative h-screen flex items-center justify-center'>
      <div className='max-w-md pt-6 pb-7 gap-6 w-full text-center rounded-2xl bg-black-875 flex flex-col items-center justify-center text-sm'>
        <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-800'>
          <CubeIcon className='h-6 w-6 text-primary' />
        </div>
        <div>
          <p className='pb-1'>You model is being built.</p>
          <p className='text-xs text-black-400'>Hang tight! Your model is almost ready for viewing.</p>
          <p className='text-xs text-black-400'>{duration}</p>
        </div>
      </div>
    </div>
  )
}
