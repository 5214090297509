import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { Context } from 'store/index'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, deleteDoc, updateDoc, doc } from 'firebase/firestore'

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

export default function Index({ project }) {
  const [state, dispatch] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  return (
    <div className='fade-in relative h-screen flex items-center justify-center'>
      <div className='max-w-md pt-6 pb-7 gap-6 w-full text-center rounded-2xl bg-black-875 flex flex-col items-center justify-center text-sm'>
        <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-800'>
          <ExclamationTriangleIcon className='h-6 w-6 text-primary' />
        </div>
        <div>
          <p className='pb-1'>Cloud processesing has been canceled.</p>
          <p className='text-xs text-black-400'>You can either restart or remove the processing task below.</p>
        </div>
        <div className='flex gap-3'>
          <button
            className='font-bold bg-primary px-4 pt-1 pb-1.5 text-black transition duration-200 hover:brightness-110 rounded-full tet-black'
            onClick={async () => {
              const body = new URLSearchParams({
                uuid: project.taskUuid
              })
              const response = await fetch('http://spark1.webodm.net/task/restart?' + new URLSearchParams({
                  token: process.env.REACT_APP_ODM_LIGHTNING
                }), {
              	method: 'POST',
                body: body
              }).then(async res => {
                const data = await res.json()
                console.log(data)
                if(data.success) {
                  setDoc(doc(db, 'site-analysis', project.id), {
                    status: {
                      value: 'Processing',
                      description: 'Your model is being processed...',
                    },
                    updatedAt: new Date(),
                    startedAt: new Date()
                  }, { merge: true })
                }
              })
            }}>
            Restart processing
          </button>
          <button
            className='font-bold bg-black-800 px-4 pt-1 pb-1.5 text-black-400 transition duration-200 hover:brightness-110 rounded-full tet-black'
            onClick={async () => {
              const body = new URLSearchParams({
                uuid: project.taskUuid
              })
              const response = await fetch('http://spark1.webodm.net/task/remove?' + new URLSearchParams({
                  token: process.env.REACT_APP_ODM_LIGHTNING
                }), {
              	method: 'POST',
                body: body
              }).then(async res => {
                const data = await res.json()
                console.log(data)
                setDoc(doc(db, 'site-analysis', project.id), {
                  status: {
                    value: 'Created',
                    description: 'This project has been created.',
                    errorMessage: deleteField()
                  },
                  updatedAt: new Date(),
                  startedAt: deleteField(),
                  taskUuid: deleteField()
                }, { merge: true })
                deleteDoc(doc(db, 'site-analysis-alerts', project.id))
              })
            }}>
            Remove
          </button>
        </div>
      </div>
    </div>
  )
}
