import { useRef, useEffect, useState, useContext, useCallback } from 'react'
import { Context } from 'store/index'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { BoltIcon } from '@heroicons/react/24/solid'

import TimeAgo from 'javascript-time-ago'

export default function Index({ project }) {
  const [state, dispatch] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const timeAgo = new TimeAgo('en-US')

  const durationInterval = useRef(null)
  const progressInterval = useRef(null)

  // const [duration, setDuration] = useState(timeAgo.format(project.startedAt, 'mini'))
  const [duration, setDuration] = useState('0s')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    durationInterval.current = setInterval(() => {
      setDuration(timeAgo.format(project.startedAt, 'mini'))
    }, 1000)

    async function getProgress() {
      console.log('progress')
      const uuid = project.taskUuid
      const response = await fetch('http://spark1.webodm.net/task/' + uuid + '/info?' + new URLSearchParams({
          token: process.env.REACT_APP_ODM_LIGHTNING
        }), {
      	method: 'GET'
      }).then(async res => {
        const data = await res.json()
        console.log('data')
        console.log(data)
        setProgress(data.progress ? data.progress.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0)
      })
    }

    progressInterval.current = setInterval(async () => {
      getProgress()
    }, 1000 * 60)

    getProgress()

    return () => {
      if(durationInterval.current) clearInterval(durationInterval.current)
      if(progressInterval.current) clearInterval(progressInterval.current)
    }
  }, [])

  return (
    <div className='fade-in relative h-screen flex items-center justify-center'>
      <div className='max-w-md pt-6 pb-7 gap-6 w-full text-center rounded-2xl bg-black-875 flex flex-col items-center justify-center text-sm'>
        <div className='flex items-center justify-center h-12 w-12 rounded-full bg-black-800'>
          <BoltIcon className='h-6 w-6 text-primary' />
        </div>
        <div>
          <p className='pb-1'>Your model is being processed...</p>
          <p className='text-xs text-black-400 pb-2'>{duration}</p>
          <div className='bg-black-750 rounded-full overflow-hidden h-1 w-full'>
            <div className='h-full bg-primary rounded-full transition-all duration-200' style={{ width: progress + '%'}} />
          </div>
          <p className='text-xs text-black-400 pt-1'>{progress}%</p>
        </div>
        <button
          className='font-bold bg-primary px-4 pt-1 pb-1.5 text-black transition duration-200 hover:brightness-110 rounded-full tet-black'
          onClick={async () => {
            const body = new URLSearchParams({
              uuid: project.taskUuid
            })
            const response = await fetch('http://spark1.webodm.net/task/cancel?' + new URLSearchParams({
                token: process.env.REACT_APP_ODM_LIGHTNING
              }), {
            	method: 'POST',
              body: body
            }).then(async res => {
              const data = await res.json()
              console.log(data)
              if(data.success) {
                setDoc(doc(db, 'site-analysis', project.id), {
                  status: {
                    value: 'Canceled',
                    description: 'Cloud processing has been canceled.',
                  },
                  updatedAt: new Date()
                }, { merge: true })
                setDoc(doc(db, 'site-analysis-alerts', project.id), {
                  status: {
                    value: 'Canceled',
                    description: 'Cloud processing has been canceled.',
                  },
                  updatedAt: new Date()
                }, { merge: true })
              }
            })
          }}>
          Cancel
        </button>
      </div>
    </div>
  )
}
