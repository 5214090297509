import { Link } from "react-router-dom"
import { useState } from 'react'

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

import hero from './images/hero.gif'

export default function Index() {
  const auth = getAuth()

  const [error, setError] = useState(false)

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  })

  async function submit(e) {
    e.preventDefault()
    setError(false)
    if(!credentials.email || !credentials.password) {
      setError('Please check that you have entered your credentials correctly.')
    } else {
      await signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(userCredential => { })
      .catch(e => {
        setError(e.message)
        console.log(e)
      })
    }
  }

  return (
    <div data-aos='fade-in' className='flex h-screen w-screen py-20'>
      <div className='flex h-full w-full'>
        <div className='hidden h-full w-1/4'>
          <div className='h-full w-full rounded-r-xl overflow-hidden bg-black-950'>
            <img src={hero} alt='Hero' className='w-full h-full object-cover object-left' />
          </div>
        </div>
        <form onSubmit={submit} className='m-auto w-full max-w-xs space-y-8'>
          <div className='gap-1.5 flex items-center justify-center'>
            <svg className='text-primary h-9' fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.02 57.51'><path d='M55.18,27.96h-7.48c-.14,0-.27,.02-.39,.04l-1.19-8.53,2.59-1.5c.49-.28,.82-.78,.9-1.34l2-14.55c.07-.53-.09-1.06-.44-1.46-.35-.4-.85-.63-1.39-.63H7.91c-.53,0-1.04,.23-1.39,.63-.35,.4-.51,.93-.44,1.46l2,14.55c.08,.56,.41,1.06,.9,1.34l2.59,1.5-.67,4.79c-.11,.03-.22,.06-.33,.11L1.07,28.76c-.65,.3-1.07,.95-1.07,1.67v9.61c0,1.02,.82,1.84,1.84,1.84h6.61l-1.28,9.16c-.1,.74,.25,1.47,.9,1.84l7.52,4.38c.28,.16,.6,.25,.93,.25h24.38c.31,0,.62-.08,.9-.23l7.82-4.38c.66-.37,1.03-1.11,.92-1.86l-2.71-19.4h7.36c1.02,0,1.84-.82,1.84-1.84s-.82-1.84-1.84-1.84Zm-10.16,10.24h-5.87v-14.69l3.53-2.04,2.34,16.73Zm-17.09-9.27c.28,.16,.6,.25,.92,.25s.64-.08,.92-.25l5.7-3.3v12.57h-13.7v-12.83l6.15,3.55ZM47.68,3.68l-1.59,11.57-17.24,9.96L11.6,15.25l-1.59-11.57H47.68ZM18.1,23.25v14.95h-5.42l2.34-16.73,3.08,1.78ZM3.68,38.2v-6.59l6.63-3.05-1.35,9.64H3.68Zm36.73,15.63H17.01l-6.03-3.51,1.18-8.45H45.53l1.18,8.42-6.3,3.53Z'/><path d='M29.81,11.65v-3.85c0-.34-.22-.64-.55-.74-.33-.1-.68,.02-.87,.31l-4.8,7.09c-.16,.24-.18,.54-.04,.8s.4,.41,.68,.41h3.21v3.85c0,.34,.22,.64,.55,.74,.08,.02,.15,.03,.23,.03,.25,0,.49-.12,.64-.34l4.8-7.09c.16-.24,.18-.54,.04-.8s-.4-.41-.68-.41h-3.21Z'/></svg>
            <p className='font-bold text-primary'>FlightPack</p>
          </div>
          <div className='p-2 space-y-2 divide-y rounded-lg overflow-hidden relative'>
            <input
              className='placeholder-black-400 w-full bg-black-825 rounded-lg appearance-none pt-3.5 pb-4 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
              type='email'
              value={credentials.email}
              autoComplete='email'
              placeholder='Email Address'
              onChange={(e) => setCredentials(prev => ({ ...prev, email: e.target.value }))} />
            <input
              className='placeholder-black-400 w-full bg-black-825 rounded-lg appearance-none pt-3.5 pb-4 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
              type='password'
              value={credentials.password}
              placeholder='Password'
              autoComplete='password'
              onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))} />
          </div>
          {error &&
            <div className='tracking-wider font-mono text-primary text-xs uppercase fade-in text-center border-2 border-primary rounded-lg py-4'>{error}</div>
          }
          <div>
            <button type='submit' className='block mx-auto bg-black-825 hover:bg-black-800 text-primary pt-2 pb-2.5 px-8 font-bold rounded-full'>Let's go!</button>
          </div>
          <div className='text-center'>
            <Link to='/forgot' className='mx-auto mt-6'>Forgot password</Link>
          </div>
        </form>
        <div className='hidden h-full w-1/12'>
          <div className='rounded-l-xl h-full w-full overflow-hidden bg-black-950'>
            <img src={hero} alt='Hero' className='w-full h-full object-cover object-right' />
          </div>
        </div>
      </div>
    </div>
  )
}
