import { Link } from 'react-router-dom'
import { useState } from 'react'

import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'

export default function Index() {
  const auth = getAuth()

  const [error, setError] = useState(false)

  const [credentials, setCredentials] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordCheck: '',
  })

  async function submit(e) {
    if(!credentials.email || !credentials.firstName || !credentials.lastName) {
      setError('Please complete all fields.')
    } else if(!passwordExist) {
      setError('Please complete password fields.')
    } else if(!passwordMatch) {
      setError('Your passwords don\'t match.')
    } else if(!passwordStrong) {
      setError('Your password is not strong enough.')
    } else {
      await createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(userCredential => { })
      .catch(e => {
        setError(error.message)
      })
    }
  }

  function passwordExist() {
    return credentials.password && credentials.passwordCheck
  }

  function passwordMatch() {
    return credentials.password === credentials.passwordCheck
  }

  function passwordStrong() {
    const check = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    return check.test(credentials.password)
  }

  return (
    <div data-aos='fade-in' className='h-screen w-screen flex'>
      <form className='fade-in m-auto w-screen max-w-xs space-y-8'
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}>
        <p className='text-center text-3xl'>
          Sign up
        </p>
        <div className='bg-black-900 p-2 divide-y rounded-lg overflow-hidden relative'>
          <input
            id='firstName'
            className='placeholder-black-400 w-full bg-black-900 rounded appearance-none pt-3 pb-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='text'
            value={credentials.firstName}
            placeholder='First Name'
            onChange={(e) => setCredentials(prev => ({ ...prev, firstName: e.target.value }))} />
          <input
            id='lastName'
            className='placeholder-black-400 w-full bg-black-900 rounded appearance-none pt-3 pb-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='text'
            value={credentials.lastName}
            placeholder='Last Name'
            onChange={(e) => setCredentials(prev => ({ ...prev, lastName: e.target.value }))} />
          <input
            id='email'
            className='placeholder-black-400 w-full bg-black-900 rounded appearance-none pt-3 pb-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='email'
            value={credentials.email}
            placeholder='Email Address'
            onChange={(e) => setCredentials(prev => ({ ...prev, email: e.target.value }))} />
          <input
            id='password'
            className='placeholder-black-400 w-full bg-black-900 rounded appearance-none pt-3 pb-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='password'
            value={credentials.password}
            placeholder='Password'
            onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))} />
          <input
            id='passwordCheck'
            className='placeholder-black-400 w-full bg-black-900 rounded appearance-none pt-3 pb-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-primary z-0 focus:z-10 relative'
            type='password'
            value={credentials.passwordCheck}
            placeholder='Password'
            onChange={(e) => setCredentials(prev => ({ ...prev, passwordCheck: e.target.value }))} />
        </div>
        {error &&
          <div className='font-mono text-black-400 text-sm uppercase fade-in text-center border-2 border-primary rounded-lg py-4'>{error}</div>
        }
        <div>
          <button type='submit' className='block mx-auto bg-black-900 hover:bg-black-850 text-primary transition duration-200 pt-2 pb-2.5 px-8 text-black font-bold rounded-full'>Create Account</button>
        </div>
        <div className='text-center'>
          <Link to='/login' className='mx-auto mt-6'>Back to login</Link>
        </div>
      </form>
    </div>
  )
}
