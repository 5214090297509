import { useRef, useEffect, useState, useContext } from 'react'

import { PlusIcon, MinusIcon, VariableIcon } from '@heroicons/react/24/outline'
import { MapIcon } from '@heroicons/react/24/solid'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'

import { Context } from 'store/index'

import * as L from 'leaflet'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import 'leaflet-tilelayer-colorpicker'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import Filter from './map-filter'

export default function Index({ project, filePaths, filePathsDsm }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://flightpack-assets')

  const [state] = useContext(Context)

  const mapRef = useRef(null)
  const currentTiles = useRef(null)
  const currentTilesOdm = useRef(null)
  const colorpicker = useRef(null)

  const drawLayerRef = useRef(null)

  const [map, setMap] = useState()
  const [bingTiles, setBingTiles] = useState()
  const [type, setType] = useState('blank')

  const [view, setView] = useState('photo')

  useEffect(() => {
    async function getBing() {
      const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
      const tiles = await fetch(url)
      const tilesJSON = await tiles.json()
      setBingTiles(tilesJSON)
    }
    getBing()
  }, [])

  useEffect(() => {
    if(mapRef.current && !map) {
      const initMap = L.map('map', {
        // center: [47.52410470108144, -122.32365354895592],
        zoom: 19,
        scrollWheelZoom: true,
        zoomControl: false,
        zoomSnap: 0.5,
        attributionControl: false,
        zoomDelta: 0.5,
        wheelPxPerZoomLevel: 120
      })
      setMap(initMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, map])

  useEffect(() => {
    async function init() {
      let newTiles = false
      if(type === 'blank') {
        newTiles = false
      } else if(type === 'roadmap-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href=\'https://www.mapbox.com/feedback/\'>Mapbox</a>',
         maxZoom: 25, maxNativeZoom: 21,
         tileSize: 512,
         zoomOffset: -1
        }).setZIndex(0).addTo(map)
      } else if(type === 'roadmap-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',{
          maxZoom: 25, maxNativeZoom: 21,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href=\'http://bing.com/maps\'>Google Maps</a>'
        }).setZIndex(0).addTo(map)
      } else if(type === 'satellite-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href=\'https://www.mapbox.com/feedback/\'>Mapbox</a>',
         maxZoom: 25, maxNativeZoom: 23,
         tileSize: 512,
         zoomOffset: -1
        }).setZIndex(0).addTo(map)
      } else if(type === 'satellite-usgs') {
        newTiles = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}', {
        	maxZoom: 25, maxNativeZoom: 21,
        	attribution: '&copy; <a href=\'https://usgs.gov/\'>U.S. Geological Survey</a>'
        }).setZIndex(0).addTo(map)
      } else if(type === 'satellite-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 25, maxNativeZoom: 22,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href=\'http://bing.com/maps\'>Google Maps</a>'
        }).setZIndex(0).addTo(map)
      } else if(type === 'satellite-bing') {
        var BingLayer = L.TileLayer.extend({
          getTileUrl: function (tilePoint) {
            // this._adjustTilePoint(tilePoint);
            return L.Util.template(this._url, {
              s: this._getSubdomain(tilePoint),
              q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
            })
          },
          getAttribution: function() {
            return '&copy; <a href=\'http://bing.com/maps\'>Bing Maps</a>'
          },
          _quadKey: function (x, y, z) {
              var quadKey = [];
              for(var i = z; i > 0; i--) {
                var digit = '0'
                var mask = 1 << (i - 1)
                if((x & mask) != 0) {
                  digit++
                }
                if((y & mask) != 0) {
                  digit++
                  digit++
                }
                quadKey.push(digit)
              }
              return quadKey.join('')
          }
        })

        const tilesUrl = bingTiles.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')

        newTiles = new BingLayer(tilesUrl, {
          maxZoom: 25, maxNativeZoom: 20,
          subdomains: bingTiles.resourceSets[0].resources[0].imageUrlSubdomains
        }).setZIndex(0).addTo(map)
      } else if(type === 'satellite-arcgis') {
        newTiles = L.tileLayer('http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 25, maxNativeZoom: 19,
          subdomains: ['server', 'services'],
          attribution: '&copy; <a href=\'http://www.arcgis.com/\'>ArcGIS esri</a>'
        }).setZIndex(0).addTo(map)
      }

      const drawnItems = new L.FeatureGroup()
      map.addLayer(drawnItems)

      drawLayerRef.current = L.featureGroup().addTo(map)
      if(project.mapDrawing) {
        const geojsonLayer = L.geoJson(JSON.parse(project.mapDrawing))
        geojsonLayer.eachLayer((l) => {
          drawLayerRef.current.addLayer(l)
        })
      }

      map.pm.setGlobalOptions({
        snappable: true,
        snapDistance: 20,
        tooltips: false,
        layerGroup: drawLayerRef.current
      })
      map.pm.addControls({
        position: 'topright',
        rotateMode: false,
        drawText: false,
        drawMarker: false,
        drawCircleMarker: false,
        // editMode: false,
        dragMode: false,
        cutPolygon: false
      })

      map.on('pm:globaldrawmodetoggled', (e) => {
        console.log(e)
        console.log('toggle')
      })
      map.on('pm:drawstart', (e) => {
        console.log(e)
        console.log('start')
      })
      map.on('pm:drawend', (e) => {
        console.log(e)
        console.log('end')
      })
      map.on('pm:remove', (e) => {
        console.log(e)
        console.log('remove')
        setDoc(doc(db, 'site-analysis', project.id), {
          mapDrawing: JSON.stringify(drawLayerRef.current.toGeoJSON()),
          updatedAt: new Date()
        }, { merge: true })
      })
      map.on('pm:create', (e) => {
        // console.log(e)
        console.log('create')
        const feature = e.layer.toGeoJSON()
        const coordinates = feature.geometry.coordinates
        const distance = L.latLng(coordinates[0][1],coordinates[0][0]).distanceTo(L.latLng(coordinates[1][1],coordinates[1][0]))
        const feet = distance * 3.2808399

        // console.log(feet)

        setDoc(doc(db, 'site-analysis', project.id), {
          mapDrawing: JSON.stringify(drawLayerRef.current.toGeoJSON()),
          updatedAt: new Date()
        }, { merge: true })
      })

      if(currentTiles.current) currentTiles.current.remove()
      currentTiles.current = newTiles
    }

    if(map && type && bingTiles) {
      init()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, type, bingTiles])

  useEffect(() => {
    if(map && view) {
      let baseUrl = false
      let currentPaths = false
      if(view === 'photo') {
        console.log('view photo')
        baseUrl = filePaths['tilemapresource.xml'].url
        currentPaths = filePaths
      } else if(view === 'dsm') {
        console.log('view dsm')
        baseUrl = filePathsDsm['tilemapresource.xml'].url
        currentPaths = filePathsDsm
      }
      const tilesUrl = baseUrl.slice(0, baseUrl.indexOf('?alt')) + '/{z}/{x}/{y}.png' + baseUrl.slice(baseUrl.indexOf('?alt'))
      if(currentTilesOdm.current) {
        currentTilesOdm.current.off('tileerror', handleTileError)
        currentTilesOdm.current.remove()
        colorpicker.current.off('tileerror', handleTileError)
        colorpicker.current.remove()
      }

      colorpicker.current = L.tileLayer.colorPicker(baseUrl, {
        maxZoom: 25,
        maxNativeZoom: 21,
        tileSize: 256,
        reuseTiles: true,
      }).addTo(map)

      map.on('mousemove', (event) => {
        var a = colorpicker.current.getColor(event.latlng)
        // console.log(a)
        var h = NaN
        if (a !== null) {
          var h = (a[0] << 16) + (a[1] << 8) + a[2]
          h = h === 0x800000 ? NaN : (h > 0x800000 ? h - 0x1000000 : h) / 100
        }
        // console.log(isNaN(h) ? 'N/A' : h.toFixed(2) + 'm')
      })

       currentTilesOdm.current = L.tileLayer.wms(baseUrl, {
         maxZoom: 25,
         maxNativeZoom: 21,
         tileSize: 256,
         reuseTiles: true,
       }).setZIndex(1).addTo(map)
// minx="-122.32398879166499" miny="47.52382130392443" maxx="-122.32311848459014" maxy="47.52438564775895"
       map.fitBounds(L.latLngBounds([[47.52381137846303, -122.32400149957206],[47.52442460044489, -122.32311308824541]]))

       map.setZoom(map.getZoom() + 1)
       function handleTileError(evt) {
        if(evt.tile._hasError) return

        const url = '{z}/{x}/{y}.png'

        let tileSrc = url
        tileSrc = tileSrc.replace(/{x}/g, evt.coords.x)
        tileSrc = tileSrc.replace(/{y}/g,  Math.pow(2, evt.coords.z) - evt.coords.y - 1)
        tileSrc = tileSrc.replace(/{z}/g, evt.coords.z)

        if(currentPaths[tileSrc]) {
          evt.tile._hasError = true
          evt.tile.src = currentPaths[tileSrc].url
        } else {
          return
        }
      }

      currentTilesOdm.current.on('tileerror', handleTileError)
      colorpicker.current.on('tileerror', handleTileError)
    }
  }, [map, view])

  return (
    <div data-aos='fade-up' className='h-full w-full'>
      <ul className='mr-3 fixed top-20vh right-10vw flex flex-col gap-1 z-10'>
        <li>
          <button
            className='group border border-black-925 transition duration-200 flex text-sm bg-black-800 text-black-100 rounded-full h-9 w-9 hover:bg-black-750 flex gap-3 items-center'
            onClick={() => map.setZoom(map.getZoom() + 1)}>
            <PlusIcon strokeWidth={2} className='group-hover:scale-125 transition duration-200 h-5 w-5 m-auto' />
          </button>
        </li>
        <li>
          <button
            className='group border border-black-925 transition duration-200 flex text-sm bg-black-800 text-black-100 rounded-full h-9 w-9 hover:bg-black-750 flex gap-3 items-center'
            onClick={() => map.setZoom(map.getZoom() - 1)}>
            <MinusIcon strokeWidth={2} className='group-hover:scale-125 transition duration-200 h-5 w-5 m-auto' />
          </button>
        </li>
        <li>
          <button
            className={'group border border-black-925 transition duration-200 transition duration-200 flex text-sm rounded-full h-9 w-9 flex gap-3 items-center ' + (view === 'photo' ? 'bg-primary hover:brightness-110' : 'bg-black-800 hover:bg-black-750')}
            onClick={() => setView('photo')}>
            <MapIcon className={'group-hover:scale-125 h-4 w-4 m-auto transition duration-200 ' + (view === 'photo' ? 'text-black' : 'text-black-100')} />
          </button>
        </li>
        <li>
          <button
            className={'group border border-black-925 transition duration-200 flex text-sm rounded-full h-9 w-9 flex gap-3 items-center ' + (view === 'dsm' ? 'bg-primary hover:brightness-110' : 'bg-black-800 hover:bg-black-750')}
            onClick={() => setView('dsm')}>
            <VariableIcon strokeWidth={2.5} className={'group-hover:scale-125 transform transition duration-200 h-4 w-4 m-auto transform translate-y-px ' + (view === 'dsm' ? 'text-black' : 'text-black-100')} />
          </button>
        </li>
      </ul>
      <div className='h-full w-full relative z-0'>
        <div ref={mapRef} id='map' className='relative z-0 bg-transparent h-full h-full overflow-hidden' />
      </div>
      <Filter type={type} setType={setType} map={map} />
    </div>
  )
}
