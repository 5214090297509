import { useState } from 'react'

import { initializeApp, getApp, deleteApp } from 'firebase/app'
import { getAuth, sendEmailVerification, sendPasswordResetEmail, createUserWithEmailAndPassword } from 'firebase/auth'
import { getFirestore, doc, setDoc } from 'firebase/firestore'

import { CheckIcon } from '@heroicons/react/24/solid'

import { passwordStrength } from 'check-password-strength'

const strengthOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 4
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 3,
    minLength: 8
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 11
  }
]

export default function Index({ modalRef, access }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [creating, setCreating] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  const [roles, setRoles] = useState([])

  function checkForm() {
    return firstName && lastName && email && roles.length && password && passwordStrength(password, strengthOptions).value === 'Strong'
  }

  function changeChecked(roleId) {
    if(!isChecked(roleId)) {
      console.log('not checked')
      setRoles(prev => ([
        ...prev, { id: roleId }
      ]))
    } else {
      console.log('checked')
      setRoles(prev => prev.filter(el => el.id.toString() !== roleId.toString()))
    }
  }

  function isChecked(roleId) {
    return roles && roles.filter(el => el.id.toString() === roleId.toString()).length > 0
  }

  return (
    <div className='max-w-full w-96 space-y-6 p-6'>
      <p className='text-center text-2xl'>Create User</p>
      <div className='p-3 bg-black-800 rounded-xl'>
        <div className='space-y-2 mb-4 text-sm'>
          <label className='text-xs block text-black-200'>Email</label>
          <input
            type='email'
            name='email'
            className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Email'
            autoComplete='new-password'
          />
          <label className='text-xs block text-black-200'>First Name</label>
          <input
            type='text'
            name='firstName'
            className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='First Name'
            autoComplete='new-password'
          />
          <label className='text-xs block text-black-200'>Last Name</label>
          <input
            type='text'
            name='lastName'
            className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='Last Name'
            autoComplete='new-password'
          />
          <label className='text-xs block text-black-200'>Access</label>
          {access &&
            <ul className='text-sm bg-black-725 rounded-lg divide-y divide-black-775'>
              {access.map(item => {
                const id = item.id
                const data = item.data()
                return (
                  <li key={id}>
                    <button
                      className='flex text-left items-center gap-3 w-full px-2 py-2'
                      onClick={(e) => {
                        changeChecked(id)
                      }}>
                      <span className='h-6 w-6 flex'>
                        <CheckIcon className={'m-auto h-5 w-5 ' + (isChecked(id) ? 'text-primary' : 'text-black-300')} />
                      </span>
                      <span className='flex-1'>{data.name}</span>
                    </button>
                  </li>
                )
              })}
            </ul>
          }
          <label className='text-xs block text-black-200'>Password</label>
          <div className='relative'>
            <input
              type={passwordShown ? 'text' : 'password'}
              className='placeholder-black-300 focus:ring-2 focus:ring-primary rounded-lg bg-black-725 hover:bg-black-700 w-full focus:outline-none p-3'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              autoComplete='new-password'
            />
            <button className='bg-black-575 leading-none px-3 py-1 rounded-full text-xs absolute top-1/2 right-2 transform -translate-y-1/2' onClick={() => setPasswordShown(!passwordShown)}>{passwordShown ? 'Hide' : 'Show'}</button>
          </div>
          <div className='flex items-center gap-2'>
            <span className={'h-3 w-3 rounded-full transition duration-200 ' + (passwordStrength(password, strengthOptions).value === 'Strong' ? 'bg-emerald-500' : 'bg-red-500')} />
            <p className='text-xs'>{passwordStrength(password, strengthOptions).value}</p>
          </div>
          <p className='text-sm text-black-400 leading-tight'>Your password must be atleast 11 characters long and include uppercase, lowercase, symbol, and number.</p>
        </div>
        {creating &&
          <div className='mt-8 mx-auto fade-in text-center mb-4 border border-primary px-8 py-4 rounded-xl'>Creating user...</div>
        }
      </div>
      <button
        disabled={checkForm() ? false : true}
        className={'block m-auto py-1.5 font-bold px-8 text-sm rounded-full ' + (checkForm() ? 'bg-primary hover:bg-primary-700 text-black' : 'bg-black-800 text-black-200')}
        onClick={async () => {
          setCreating(true)

          const config = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
          }

          const firebaseApp = initializeApp(config, 'secondary')
          const auth2 = getAuth(firebaseApp)

          await createUserWithEmailAndPassword(auth2, email, password).then(async userRecord => {
            await setDoc(doc(db, 'users', userRecord.user.uid), {
              uid: userRecord.user.uid,
              email: email,
              name: firstName + ' ' + lastName,
              firstName: firstName,
              lastName: lastName,
              createdAt: new Date(),
              updatedAt: new Date(),
              roles: {
                flightpack: roles
              }
            }, { merge: true })

            await setDoc(doc(db, 'flightpack-users-settings', userRecord.user.uid), {
              uid: userRecord.user.uid,
              createdAt: new Date(),
              updatedAt: new Date()
            }, { merge: true })

            await sendEmailVerification(auth2.currentUser)
            await sendPasswordResetEmail(auth2, email)

            console.log('User created successfully!')
            console.log(userRecord)
          })

          await auth2.signOut()
          await deleteApp(firebaseApp)

          setCreating(false)
          modalRef.current.close()
        }}>
        Add User
      </button>
    </div>
  )
}
