import { useContext } from 'react'

import Logo from 'components/logo'
import Profile from './profile'
import AddProject from './add-project'

import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'

import { Context } from 'store/index'

export default function Index() {
  const [state, dispatch] = useContext(Context)
  return (
    <>
      <Logo />
      <ul className='fade-down fixed top-0 right-0 m-3 z-10 flex gap-2'>
        <li>
          <AddProject />
        </li>
        <li className='hidden'>
          <button
            className='group h-10 w-10 rounded-full bg-black-825 hover:bg-black-800 transition duration-200 flex items-center justify-center'>
            <MagnifyingGlassIcon strokeWidth={3.5} className='w-4 h-4 transition duration-200 text-primary group-hover:scale-110' />
          </button>
        </li>
        <li><Profile /></li>
      </ul>
    </>
  )
}
