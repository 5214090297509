const Reducer = (state, action) => {
  switch (action.type) {
    case 'toast-set':
      return {
        ...state,
        toast: action.payload
      }
    case 'user-set':
      return {
        ...state,
        user: action.payload
      }
    case 'settings-set':
      return {
        ...state,
        settings: action.payload
      }
    case 'profile-set':
      return {
        ...state,
        profile: action.payload
      }
    case 'contact-properties-set':
      return {
        ...state,
        contactProperties: action.payload
      }
    case 'contact-owners-set':
      return {
        ...state,
        contactOwners: action.payload
      }
    case 'start-uploads-set':
      return {
        ...state,
        startUploads: action.payload
      }
    case 'uploads-set':
      return {
        ...state,
        uploads: action.payload
      }
    case 'uploads-add':
      return {
        ...state,
        uploads: [
          ...state.uploads,
          action.payload
        ]
      }
    case 'uploads-update':
      const update = state.uploads.map(u => {
        if(u.id === action.payload.id) {
          return {
            ...u,
            ...action.payload
          }
        } else {
          return u
        }
      })
      return {
        ...state,
        uploads: [
          ...update
        ]
      }
    case 'uploads-remove':
      const uploads = state.uploads.filter(u => u.id !== action.payload.id)
      return {
        ...state,
        uploads: uploads
      }
    default:
      return state
  }
}

export default Reducer
