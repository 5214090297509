import { useState, useEffect, useContext } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'store/index'

import { UserIcon } from '@heroicons/react/24/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

import ProfileImage from './profile-image'

import { getAuth } from 'firebase/auth'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)
  const [profile, setProfile] = useState(false)
  const [settings, setSettings] = useState(false)

  useEffect(() => {
    setProfile(state.profile)
  }, [state.profile])

  useEffect(() => {
    setSettings(state.settings)
  }, [state.settings])

  return (
    <div>
      <button
        onClick={() => setShow(!show)}
        className='group bg-black-825 hover:bg-black-800 flex h-10 w-10 transition duration-200 rounded-full'>
        <UserIcon className='transition text-primary duration-200 group-hover:scale-125 h-4 w-4 m-auto' />
      </button>

      <Modal profile={profile} settings={settings} showModal={show} setShowModal={setShow} />
    </div>
  )
}

function Modal({ profile, settings, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const auth = getAuth(firebaseApp)

  const [state, dispatch] = useContext(Context)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-xs transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='flex-1 overflow-scroll'>
          <div className='sticky top-0 z-10 bg-black-850 bg-opacity-40 backdrop-filter backdrop-blur-md'>
            <div className='p-3'>
              <button
                onClick={() => {
                  setShowModal(false)
                }}
                className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
                <XMarkIcon strokeWidth={1.75} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
              </button>
            </div>
            <div className='relative p-2 space-y-6 py-6'>
              <ProfileImage />
              <div className='flex items-center gap-3'>
                <label htmlFor='units' className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={settings.units ? true : false}
                    id='units'
                    onChange={(e) => {
                      const units = settings.units === 'Metric' ? 'Standard' : 'Metric'
                      setDoc(doc(db, 'flightpack-users-settings', state.user.uid), {
                        units: units,
                        updatedAt: new Date()
                      }, { merge: true })
                    }}
                    className='cursor-pointer h-full w-full sr-only peer' />
                  <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (settings.units === 'Metric' ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                </label>
                <span className={'text-xs ' + (settings.units === 'Metric' ? 'text-white' : 'text-black-400')}>Metric</span>
              </div>
              <ul className='flex-1 divide-y bg-black-800 p-2 rounded-2xl'>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>First Name</p>
                    <p className='truncate text-sm'>{profile && profile.firstName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>Last Name</p>
                    <p className='truncate text-sm'>{profile && profile.lastName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>Email</p>
                    <p className='truncate text-sm'>{profile && profile.email}</p>
                  </div>
                </li>
              </ul>
              <button className='font-bold text-black bg-primary hover:brightness-110 transition duration-200 py-1.5 text-sm rounded-full px-8' onClick={() => auth.signOut()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}
