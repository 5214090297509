import { useRef, useEffect, useState, useContext, useCallback } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'store/index'

import { Squares2X2Icon } from '@heroicons/react/24/solid'
import { ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline'

import MenuMain from 'components/menu-main'

import Grid from './components/view-grid'
import List from './components/view-list'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)
  const projectsUnsub = useRef(false)

  const [view, setView] = useState('grid')

  const [search, setSearch] = useState('')

  const [page, setPage] = useState(false)
  const [projects, setProjects] = useState(false)
  const [isPaging, setIsPaging] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(projectsUnsub.current) projectsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(search) debouncedGetProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedGetProjects = useDebouncedCallback(() => {
    getProjects()
  }, 1000)

  useEffect(() => {
    if(search) {
      debouncedGetProjects()
    } else if(search !== false) {
      getProjects()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  async function getProjects() {
    if(projectsUnsub.current) projectsUnsub.current()

    let q = null
    if(search && search !== '') {
      q = query(collection(db, 'site-analysis'), orderBy('createdAt', 'desc'), where('keyWords', 'array-contains', search.toLowerCase()), limit(25))
    } else {
      q = query(collection(db, 'site-analysis'), orderBy('createdAt', 'desc'), limit(25))
    }

    projectsUnsub.current = onSnapshot(q, (querySnapshot) => {
      if(mounted.current) {
        if(!querySnapshot.empty) {
          setProjects(querySnapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            }
          }))
          setPage({
            hasMore: true,
            vidOffset: querySnapshot.docs[querySnapshot.docs.length - 1].data().createdAt.toDate()
          })
        } else {
          setProjects(null)
        }
      } else {
        setProjects(null)
      }
    })
  }

  async function loadMore() {
    setIsPaging(true)
  }

  return (
    <>
      <MenuMain />
      <div className='fade-in pt-20vh'>
        <div className='hidden pt-24 bg-black-900 bg-opacity-90 backdrop-filter backdrop-blur-md sticky top-0 z-10'>
          <div className='flex items-end justify-between px-3 pb-3'>
            <p className='hidden text-primary text-sm font-bold'>Projects</p>
            <ul className='hidden flex gap-2'>
              {/*
                <>
                  {view === 'list' &&
                    <li>
                      <button
                        onClick={() => setView('grid')}
                        className='group h-10 w-10 rounded-full bg-black-825 hover:bg-black-800 transition duration-200 flex items-center justify-center'>
                        <Squares2X2Icon className='w-4 h-4 transition duration-200 text-primary group-hover:scale-125' />
                      </button>
                    </li>
                  }
                  {view === 'grid' &&
                    <li>
                      <button
                        onClick={() => setView('list')}
                        className='group h-10 w-10 rounded-full bg-black-825 hover:bg-black-800 transition duration-200 flex items-center justify-center'>
                        <ListBulletIcon strokeWidth={2} className='w-5 h-5 transition duration-200 text-primary group-hover:scale-110' />
                      </button>
                    </li>
                  }
                </>
              */}
            </ul>
          </div>
        </div>
        {/*
        <button onClick={() => {
          dispatch({ type: 'uploads-set', payload: {
            id: 'test',
            title: 'Test',
            address: 'test address',
            status: {
              value: 'Pushing',
              description: 'You images are being pushed to the cloud for processing...'
            }
          }})
        }}>Test add</button>
        <button onClick={() => {
          dispatch({ type: 'uploads-remove', payload: {
            id: 'test',
          }})
        }}>Test remove</button>
        */}
        <div className='relative p-4'>
          <input
            autoComplete='off'
            id='search'
            className='bg-black-800 rounded-lg focus:ring-2 focus:ring-primary transition duration-200 appearance-none w-full py-3 px-3 text-sm placeholder-black-300 focus:outline-none'
            type='text'
            placeholder='Search'
            value={search}
            onChange={(e) => {
              setProjects(false)
              setSearch(e.target.value)
            }}
          />
          {search !== '' &&
            <button onClick={() => setSearch('')} className='group absolute right-6 transition duration-200 top-1/2 transform -translate-y-1/2 h-5 w-5 rounded-full hover:brightness-110 flex items-center justify-center bg-primary text-black'>
              <XMarkIcon strokeWidth={2.5} className='transition duration-200 group-hover:rotate-90 h-3.5 w-3.5' />
            </button>
          }
        </div>
        <div className='pb-15vh px-3'>
          {projects === false && <p className='text-sm'>Looking for projects...</p>}
          {projects === null && <p className='text-sm'>No projects found.</p>}
          {view === 'grid' && projects && <Grid projects={projects} />}
          {view === 'list' && projects && <List projects={projects} />}
        </div>
      </div>
    </>
  )
}
