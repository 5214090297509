import { useRef, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, setDoc, onSnapshot } from 'firebase/firestore'

import { firebaseLists, passwordStale } from 'lib/utils'

import { Context } from 'store/index'

import Loading from './loading'
import Authenticated from './authenticated'
import NotAuthenticated from './not-authenticated'
import EmailSetup from './email-setup'
import AccountSetup from './account-setup'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const auth = getAuth(firebaseApp)

  const location = useLocation()
  const navigate = useNavigate()

  const mounted = useRef(null)
  const firstLoad = useRef(true)

  const userUnsub = useRef(null)
  const settingsUnsub = useRef(null)
  const profileUnsub = useRef(null)

  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])

  useEffect(() => {
    userUnsub.current = onAuthStateChanged(auth, user => {
      async function getProfile() {
        if(profileUnsub.current) profileUnsub.current()
        profileUnsub.current = onSnapshot(doc(db, 'users', user.uid), { includeMetadataChanges: true }, (doc) => {
          if(mounted.current === true && doc.exists()) {
            dispatch({ type: 'profile-set', payload: doc.data() })
          } else {
            dispatch({ type: 'profile-set', payload: null })
            createProfile(user)
          }
        })
      }

      async function getSettings() {
        settingsUnsub.current = onSnapshot(doc(db, 'flightpack-users-settings', user.uid), { includeMetadataChanges: true }, settings => {
          if(mounted.current === true && settings.exists()) {
            dispatch({ type: 'settings-set', payload: settings.data() })
          } else {
            // console.log('no profile')
            dispatch({ type: 'settings-set', payload: null })
            createSettings(user.uid)
          }
        })
      }

      if(user) {
        dispatch({ type: 'user-set', payload: user })
        getProfile()
        getSettings()
      } else {
        if(location.pathname !== '/login' || location.pathname !== '/signup') {
          navigate('/login')
        }
        dispatch({ type: 'user-set', payload: false })
        dispatch({ type: 'settings-set', payload: false })
        dispatch({ type: 'profile-set', payload: false })
      }
    })

    return () => {
      if(userUnsub.current) userUnsub.current()
      if(settingsUnsub.current) settingsUnsub.current()
      if(profileUnsub.current) profileUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createSettings(uid) {
    setDoc(doc(db, 'flightpack-users-settings', uid), {
      uid: uid,
      createdAt: new Date(),
      updatedAt: new Date()
    }, { merge: true })
  }

  function createProfile(user) {
    setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      createdAt: new Date(),
      updatedAt: new Date()
    }, { merge: true })
  }

  function passwordCheck() {
    if(state.user && (!state.user.emailVerified || (!state.user.reloadUserInfo || passwordStale(state.user.reloadUserInfo.passwordUpdatedAt)))){
      // console.log('Password reset needed')
      return true
    } else {
      // console.log('Password is good')
      return false
    }
  }

  if(!state.user) return <NotAuthenticated />
  if(state.user && !state.profile) return <Loading />
  if(state.user && passwordCheck()) return <EmailSetup />
  // if(state.user && state.profile && !state.settings) return <AccountSetup />
  if(state.user && state.profile) return <Authenticated />
}
