import { useEffect, useState, useRef, createRef, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, collection, query, onSnapshot, orderBy } from 'firebase/firestore'

import { Context } from 'store/index'

import ReactTooltip from 'react-tooltip'
import { PencilIcon, EnvelopeIcon, UserIcon } from '@heroicons/react/24/solid'
import { PlusIcon } from '@heroicons/react/24/outline'

import Modal from 'components/modal'

import AddUser from './components/user-add'
import EditUser from './components/user-edit'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const auth = getAuth()

  const [state, dispatch] = useContext(Context)

  const mounted = useRef(false)
  const usersUnsub = useRef(null)
  const accessUnsub = useRef(null)
  const addModal = useRef(null)
  const [editUserModalRef, setEditUserModalRef] = useState([])

  const [users, setUsers] = useState(false)
  const [access, setAccess] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(usersUnsub.current) usersUnsub.current()
      if(accessUnsub.current) accessUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getUsers() {
      const q = query(collection(db, 'users'), orderBy('createdAt', 'desc'))
      usersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) setUsers(querySnapshot.docs)
          else setUsers(null)
        }
      })
    }
    async function getAccess() {
      const q = query(collection(db, 'users-access'), orderBy('name', 'asc'))
      accessUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) setAccess(querySnapshot.docs)
          else setAccess(null)
        }
      })
    }
    getUsers()
    getAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(users) {
      setEditUserModalRef(refs => (
        Array(users.length).fill().map((_, i) => refs[i] || createRef())
      ))
    }
  }, [users])

  return (
    <div data-aos='fade-in' className='px-3 w-full h-screen'>
      <div className='flex'>
        <div className='w-1/4 h-screen sticky top-0 py-10vh'>
          <div className='p-3 space-y-3 h-full flex flex-col justify-between items-start'>
            <p className='mb-10vh font-mono uppercase text-sm tracking-wide'>Users</p>
            <p>
              <Modal ref={addModal} center={true}>
                <button trigger='true' onClick={() => addModal.current.open()} className='bg-primary text-black hover:brightness-110 transition duration-200 rounded-full pl-2 pr-4 py-1 flex gap-2 items-center'>
                  <PlusIcon strokeWidth={3} className='h-3.5 w-3.5' />
                  <span className='-mt-px text-sm font-bold'>Create user</span>
                </button>
                <AddUser content='true' access={access} modalRef={addModal} />
              </Modal>
            </p>
          </div>
        </div>
        <div className='flex-1 py-10vh'>
          {!users || !access ?
            <div>
              <div className='h-4 w-4 animate-ping bg-green-500 rounded-full m-auto' />
            </div>
            :
            <div>
              <ul data-aos='fade-in' className='text-sm divide-y'>
                {users.map((user, index) => {
                  const id = user.id
                  const data = user.data()
                  return (
                    <li key={id} className='px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                      <span className='flex items-center'>
                        {data.photo &&
                          <span className='h-9 w-9 rounded-full overflow-hidden'>
                            <img alt='Profile Avatar' className='fade-in h-10 w-10' src={data.photo.url} />
                          </span>
                        }
                        {!data.photo &&
                          <span className='text-black-400 h-8 w-8 rounded-full bg-black-800 flex items-center justify-center'>
                            <UserIcon className='h-3 w-3 m-auto' />
                          </span>
                        }
                      </span>
                      <span className='flex-1 truncate'>
                        <span>{data.firstName} {data.lastName}</span>
                      </span>
                      <span className='flex-1 truncate text-black-500'>
                        {data.email}
                      </span>
                      {state.profile.access && state.profile.access.global && state.profile.access.global.some(item => 'Super Admin' === item) &&
                        <span className='flex space-x-2'>
                          <button
                            data-tip='Send password reset email'
                            data-for={id + '-email'}
                            data-delay-show={1000}
                            onClick={() => {
                              dispatch({ type: 'toast-set', payload: 'Sending email.' })
                              sendPasswordResetEmail(auth, data.email)
                              .then(() => {
                                dispatch({ type: 'toast-set', payload: false })
                                setTimeout(() => {
                                  dispatch({ type: 'toast-set', payload: 'Email sent!' })
                                }, 400)
                              })
                              .catch((error) => {
                                console.log(error.message)
                                dispatch({ type: 'toast-set', payload: error.message })
                              })
                            }}
                            trigger='true'
                            className='h-7 w-7 text-black-300 rounded-full bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white flex'>
                            <EnvelopeIcon className='h-3.5 w-3.5 m-auto' />
                          </button>

                          <ReactTooltip
                            className='text-sm'
                            id={id + '-email'}
                            place='bottom'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />

                          <Modal ref={editUserModalRef[index]} center={true}>
                            <button
                              data-tip='Edit user'
                              data-for={id + '-edit'}
                              data-delay-show={1000}
                              onClick={() => {
                                editUserModalRef[index].current.open()
                              }}
                              trigger='true'
                              className='h-7 w-7 text-black-300 rounded-full bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white flex'>
                              <PencilIcon className='h-3.5 w-3.5 m-auto' />
                            </button>
                            <EditUser key={id} content='true' access={access} user={data} modalRef={editUserModalRef[index]} />
                          </Modal>

                          <ReactTooltip
                            className='text-sm'
                            id={id + '-edit'}
                            place='bottom'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </span>
                      }
                    </li>
                  )
                })}
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
