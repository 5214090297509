import { useContext } from 'react'
import { Context } from 'store/index'
import Color from 'color'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

// const red = [255,0,75]
const blue = [75,0,255]
const green = [0,255,75]

export function getStateByZip(zipString) {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if(typeof zipString !== 'string') {
    console.log('Must pass the zipcode as a string.')
    return
  }

  /* Ensure we have exactly 5 characters to parse */
  if(zipString.length !== 5) {
    console.log('Must pass a 5-digit zipcode.')
    return
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10)

  let st
  let state

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    st = 'AL'
    state = 'Alabama'
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    st = 'AK'
    state = 'Alaska'
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    st = 'AZ'
    state = 'Arizona'
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    st = 'AR'
    state = 'Arkansas'
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    st = 'CA'
    state = 'California'
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    st = 'CO'
    state = 'Colorado'
  } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
    st = 'CT'
    state = 'Connecticut'
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    st = 'DE'
    state = 'Delaware'
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    st = 'FL'
    state = 'Florida'
  } else if ( (zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999) ) {
    st = 'GA'
    state = 'Georgia'
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    st = 'HI'
    state = 'Hawaii'
  } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
    st = 'ID'
    state = 'Idaho'
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    st = 'IL'
    state = 'Illinois'
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    st = 'IN'
    state = 'Indiana'
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    st = 'IA'
    state = 'Iowa'
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    st = 'KS'
    state = 'Kansas'
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    st = 'KY'
    state = 'Kentucky'
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    st = 'LA'
    state = 'Louisiana'
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    st = 'ME'
    state = 'Maine'
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    st = 'MD'
    state = 'Maryland'
  } else if ( (zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544 ) ) {
    st = 'MA'
    state = 'Massachusetts'
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    st = 'MI'
    state = 'Michigan'
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    st = 'MN'
    state = 'Minnesota'
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    st = 'MS'
    state = 'Mississippi'
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    st = 'MO'
    state = 'Missouri'
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    st = 'MT'
    state = 'Montana'
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    st = 'NC'
    state = 'North Carolina'
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    st = 'ND'
    state = 'North Dakota'
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    st = 'NE'
    state = 'Nebraska'
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    st = 'NV'
    state = 'Nevada'
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    st = 'NH'
    state = 'New Hampshire'
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    st = 'NJ'
    state = 'New Jersey'
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    st = 'NM'
    state = 'New Mexico'
  } else if ( (zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544) ) {
    st = 'NY'
    state = 'New York'
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    st = 'OH'
    state = 'Ohio'
  } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999) ) {
    st = 'OK'
    state = 'Oklahoma'
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    st = 'OR'
    state = 'Oregon'
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    st = 'PA'
    state = 'Pennsylvania'
  } else if (zipcode >= 300 && zipcode <= 999) {
    st = 'PR'
    state = 'Puerto Rico'
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    st = 'RI'
    state = 'Rhode Island'
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    st = 'SC'
    state = 'South Carolina'
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    st = 'SD'
    state = 'South Dakota'
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    st = 'TN'
    state = 'Tennessee'
  } else if ( (zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) ||  (zipcode >= 88500 && zipcode <= 88599) ) {
    st = 'TX'
    state = 'Texas'
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    st = 'UT'
    state = 'Utah'
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    st = 'VT'
    state = 'Vermont'
  } else if ( (zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598) ) {
    st = 'VA'
    state = 'Virginia'
  } else if ( (zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999) ) {
    st = 'DC'
    state = 'Washington DC'
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    st = 'WA'
    state = 'Washington'
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    st = 'WV'
    state = 'West Virginia'
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    st = 'WI'
    state = 'Wisconsin'
  } else if ( (zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414 ) {
    st = 'WY'
    state = 'Wyoming'
  } else {
    st = 'none'
    state = 'none'
    console.log('No state found matching', zipcode)
  }

  return state
}

export const firebaseLists = [
  // {
  //   name: 'Running',
  //   listId: 'running',
  //   value: 'NEW',
  //   type: 'firebase',
  //   color: Color.rgb(green).mix(Color.rgb(blue), .2).rgb().string()
  // },
  {
    label: 'New Leads',
    value: 'NEW',
    // color: Color.rgb(red).mix(Color.rgb(blue), 0).rgb().string()
    color: Color.rgb(green).mix(Color.rgb(blue), .2).rgb().string()
  },
  {
    label: 'In Progress',
    value: 'Warm',
    color: Color.rgb(green).mix(Color.rgb(blue), .4).rgb().string()
  },
  {
    label: 'Follow Up',
    value: 'canCall',
    color: Color.rgb(green).mix(Color.rgb(blue), .6).rgb().string()
  },
  {
    label: 'Reconnect',
    value: 'canCall',
    color: Color.rgb(green).mix(Color.rgb(blue), .8).rgb().string()
  }
]

export const defaults = {
  wrapUpTimeStep: 30,
  wrapUpTimeMax: 210
}

export const lifeOptions = [
  {
    value: '1d',
    label: '1 day'
  },
  {
    value: '1w',
    label: '1 week'
  },
  {
    value: '2w',
    label: '2 weeks'
  },
  {
    value: '1m',
    label: '1 month'
  },
  {
    value: '3m',
    label: '3 months'
  },
  {
    value: '6m',
    label: '6 months'
  },
  {
    value: '1y',
    label: '1 year'
  }
]

export const contactProperties = [
  {
    value: 'firstname',
    label: 'First Name'

  },
  {
    value: 'lastname',
    label: 'Last Name'
  },
  {
    value: 'formatted_address',
    label: 'Address',
  },
  {
    value: 'hubspot_owner_id',
    label: 'Contact Owner',
    edit: true,
    options: true,
    format: (id) => {
      return GetOwner(id)
    }
  },
  {
    value: 'service_area',
    label: 'Service Area',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('service_area', value)
    }
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone',
    label: 'Phone',
    format: (number) => {
      return getPhoneNumber(number)
    }
  },
  {
    value: 'hs_lead_status',
    label: 'Lead Status',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('hs_lead_status', value)
    }
  },
  {
    value: 'lifecyclestage',
    label: 'Lifecycle Stage',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('lifecyclestage', value)
    }
  },
  {
    value: 'last_call_date',
    label: 'Last Call Date'
  },
  {
    value: 'outbound_call_number',
    label: 'Outbound Call Count',
  },
  {
    value: 'call_center_owner',
    label: 'Call Center Owner',
    options: true,
    format: (id) => {
      return GetOwner(id)
    }
  }
]

export const personalizationTokens = [{
  id: 'schedule_link',
  display: 'My scheduler',
  type: 'u-token'
},{
  id: 'name',
  display: 'My name',
  type: 'u-token'
},{
  id: 'email',
  display: 'My email',
  type: 'u-token'
},{
  id: 'firstname',
  display: 'First name',
  type: 'p-token'
},{
  id: 'lastname',
  display: 'Last name',
  type: 'p-token'
},{
  id: 'full_address',
  display: 'Address',
  type: 'p-token'
},{
  id: 'utility',
  display: 'Provider',
  type: 'p-token'
},{
  id: 'average_monthly_electric_bill',
  display: 'Average bill',
  type: 'p-token'
}]

export const yearsReports = [...Array(new Date().getFullYear() - 2016 + 1)].map((_, i) => ({
  label: (2016 + i).toString(),
  value: 2016 + i
}))

export const colorsReports = [
  'rgb(0 188 112)',
  'rgb(168,85,247)',
  'rgb(234 179 8)',
  'rgb(59 130 246)',
  'rgb(16 185 129)',
  'rgb(239 68 68)'
]

export const quarters = [...Array(4)].map((a,i) => ({
  label: 'Quarter — ' + (i + 1),
  value: i,
  months: [i+(2*i)+0,i+(2*i)+1,i+(2*i)+2]
}))

export const months = [
  {
    value: 0,
    label: 'Jan',
    long: 'January'
  },
  {
    value: 1,
    label: 'Feb',
    long: 'February'
  },
  {
    value: 2,
    label: 'Mar',
    long: 'March'
  },
  {
    value: 3,
    label: 'Apr',
    long: 'April'
  },
  {
    value: 4,
    label: 'May',
    long: 'May'
  },
  {
    value: 5,
    label: 'Jun',
    long: 'June'
  },
  {
    value: 6,
    label: 'Jul',
    long: 'July'
  },
  {
    value: 7,
    label: 'Aug',
    long: 'August'
  },
  {
    value: 8,
    label: 'Sep',
    long: 'September'
  },
  {
    value: 9,
    label: 'Oct',
    long: 'October'
  },
  {
    value: 10,
    label: 'Nov',
    long: 'November'
  },
  {
    value: 11,
    label: 'Dec',
    long: 'December'
  }
]

export function getDaysInMonth(month, year) {
  console.log(month, year)
  return new Date(year, month, 0).getDate()
}

export function getSanitized(val) {
  return val.toLowerCase().replace(/[^a-zA-Z0-9\s!?]+/g, '').replace(/   /g,"_").replace(/  /g,"_").replace(/ /g,"_")
}

export function passwordStale(date) {
  const duration = 180 // 6 months
  const pastTime = new Date(date)
  const now = new Date()

  const thirtyDaysInMs = duration * 24 * 60 * 60 * 1000
  const timeDiffInMs = now.getTime() - pastTime.getTime()

  if(timeDiffInMs >= thirtyDaysInMs){
    // console.log('Password is stale')
    return true
  } else {
    // console.log('Password is fresh')
    return false
  }
}

export function getBrowser() {
  var sBrowser, sUsrAg = navigator.userAgent
  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'firefox'
    // 'Mozilla/5.0 (X11 Ubuntu Linux x86_64 rv:61.0) Gecko/20100101 Firefox/61.0'
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'samsung'
    // 'Mozilla/5.0 (Linux Android 9 SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'opera'
    // 'Mozilla/5.0 (Macintosh Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106'
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'ie'
    // 'Mozilla/5.0 (Windows NT 10.0 WOW64 Trident/7.0 .NET4.0C .NET4.0E Zoom 3.6.0 wbx 1.0.0 rv:11.0) like Gecko'
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'edge'
    // 'Mozilla/5.0 (Windows NT 10.0 Win64 x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299'
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'chrome'
    // 'Mozilla/5.0 (X11 Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36'
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'safari'
    // 'Mozilla/5.0 (iPhone CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306'
  } else {
    sBrowser = 'unknown'
  }
  return sBrowser
}
export function getFormattedTime(duration) {
  let hours = Math.floor(duration / 3600)
  let minutes = Math.floor((duration - (hours * 3600)) / 60)
  let seconds = Math.floor(duration - (hours * 3600) - (minutes * 60))

  if(hours < 10) { hours = '0' + hours }
  if(minutes < 10) { minutes = '0' + minutes }
  if(seconds < 10) { seconds = '0' + seconds }

  return minutes + ':' + seconds
}

export function getHMSTime(duration) {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - (hours * 3600)) / 60)
  const seconds = Math.floor(duration - (hours * 3600) - (minutes * 60))

  const hDisplay = hours > 0 ? hours + (hours === 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = seconds > 0 ? seconds + (seconds === 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export function getName(contact) {
  let name = ''
  if(contact.firstname || contact.lastname) {
    name = (contact.firstname ? contact.firstname + ' ' : '') + (contact.lastname ? contact.lastname : '')
  } else if(contact.email) {
    name = contact.email
  } else if(contact.to) {
    name = contact.to
  }
  return name
}

export function getAddress(contact, value) {
  let address = contact.formatted_address ? contact.formatted_address : false
  if(!address) {
    address = contact.address ? toTitleCase(contact.address) : false
    if(address) {
      if(contact.city) address += ', ' + toTitleCase(contact.city)
      if(contact.state) address += ', ' + (contact.state.length === 2 ? contact.state : toTitleCase(contact.state))
      if(contact.zip) address += ', ' + toTitleCase(contact.zip)
    }
  }
  return address ? address : value
}

export function getOwnerName(contact) {
  if(contact) {
    let name = ''
    if(contact.firstName || contact.lastName) {
      name = (contact.firstName ? contact.firstName + ' ' : '') + (contact.lastName ? contact.lastName : '')
    } else {
      name = contact.email
    }
    return name
  } else {
    return '...'
  }
}

export function getPhoneNumber(number) {
  // if(number) return phoneUtil.format(phoneUtil.parseAndKeepRawInput(number, 'US'), PNF.INTERNATIONAL)
  if(number) return phoneUtil.format(phoneUtil.parseAndKeepRawInput(number, 'US'), PNF.E164)
  else return 'error'
}

export function GetOwner(id) {
  const [state] = useContext(Context)
  if(state.owners) {
    return getOwnerName(state.owners.filter(owner => parseInt(owner.id) === parseInt(id))[0])
  } else {
    return '...'
  }
}

export function GetProperty(name, value) {
  const [state] = useContext(Context)
  if(state.properties) {
    const property = state.properties.filter(property => property.name === name)[0]
    return property.options.filter(option => option.value === value)[0].label
  } else {
    return '...'
  }
}
