import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import 'lib/firebase'

import Store from 'store/index'
import Routes from 'components/routes'
import Toast from 'components/toast'

import AOS from 'aos'

function App() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
      duration: 600,
      delay: 200
    })
  }, [])

  return (
    <Store>
      <Router>
        <Routes />
      </Router>
      <Toast />
    </Store>
  )
}

export default App
